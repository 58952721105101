/* eslint-disable react/display-name */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { View, ScrollView } from 'react-native';
import {Text, Button, DataTable, Menu, IconButton, Divider, Colors} from 'react-native-paper';
import moment from 'moment';
import TheHunt from '../constants/Hunt';
import { fullWidthPadding } from '../constants/Misc';
import { calculateScoresForDay, addVpForTeam, removeVpForTeam, updateTopTen } from '../api/scoring';
import { getTeamColor } from '../../utilities/getColorForTeam';
import { getAllTeams } from '../api/teams';
import { notifyEveryone } from '../api/notifications';

export default function ScoresScreen({navigation}) {
  const [currentDay, setCurrentDay] = useState(-1);
  const [selectedDay, setSelectedDay] = useState(-1);
  const [dayMenuVisible, setDayMenuVisible] = useState(false);
  const [teams, setTeams] = useState([]);

  const [soloResults, setSoloResults] = useState([]);
  const [contestResults, setContestResults] = useState([]);
  const [teamResults, setTeamResults] = useState([]);

  const [soloEnabled, setSoloEnabled] = useState(true);
  const [teamEnabled, setTeamEnabled] = useState(true);
  const [contestEnabled, setContestEnabled] = useState(true);

  const calculateSolo = () => {
    calculateScoresForDay(selectedDay, 'solo').then(setSoloResults);
  };

  const calculateContest = () => {
    calculateScoresForDay(selectedDay, 'contest').then(setContestResults);
  };

  const calculateTeam = () => {
    calculateScoresForDay(selectedDay, 'team').then(setTeamResults);
  };

  const updateTopTenPlayers = () => {
    updateTopTen();
  };

  const applyResults = (results) => {
    results.forEach(result => {
      const team = teams.find(t => t.name === result.team);
      addVpToTeam(result.vp, team);
    });
  };

  const addVpToTeam = (vp, team) => {
    addVpForTeam(vp, team).then(updateTeams);
  };

  const removeVpFromTeam = (vp, team) => {
    removeVpForTeam(vp, team).then(updateTeams);
  };

  const updateTeams = () => {
    getAllTeams().then(setTeams);
  };

  const notifyEveryoneOfScores = () => {
    notifyEveryone(null, 'All scores have been updated.', 'From the Lion Turtle', () => alert('Players notified.'));
  };

  useEffect(() => {
    updateTeams();
    const today = new Date();
    // today.setDate(20);
    let dayDiff = moment(today).diff(TheHunt.startDate, 'days');
    if (dayDiff >= 7) dayDiff = 6;
    setCurrentDay(dayDiff + 1);
    setSelectedDay(dayDiff + 1);
  }, []);

  useLayoutEffect(() => {
    navigation.setOptions({
      title: `Scoring Day ${selectedDay}`,
      headerRight: () => (
        <Menu
          visible={dayMenuVisible}
          onDismiss={() => setDayMenuVisible(false)}
          anchor={
            <IconButton size={35} icon='dots-horizontal' onPress={() => setDayMenuVisible(true)} />
          }
        >
          {currentDay <= 0 && <Menu.Item title="Other days not available to view" />}
          {currentDay >= 1 && Array(currentDay).fill(0).map((_, i) => (
            <Menu.Item
              key={i}
              onPress={() => {
                setSelectedDay(i + 1);
                setDayMenuVisible(false);
              }}
              title={`View Day ${i+1}`}
            />
          ))}
          <Divider />
          <Menu.Item onPress={() => setDayMenuVisible(false)} title="Close Menu" />
        </Menu>
      )
    });
  }, [navigation, dayMenuVisible, selectedDay]);

  return (
    <ScrollView style={{margin: 12}} contentContainerStyle={{alignItems: 'center'}}>
      <View style={{ width: '100%', maxWidth: fullWidthPadding}}>
        <View style={{marginBottom: 48, alignItems: 'center'}}>
          <Text style={{fontSize: 18}}>Scoring</Text>
          <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly'}}>
            <IconButton icon='minus' onPress={() => setSelectedDay(selectedDay - 1)} />
            <Button color={Colors.white} mode='outlined' style={{width: 200}}>Day {selectedDay}</Button>
            <IconButton icon='plus' onPress={() => setSelectedDay(selectedDay+1)} />
          </View>
        </View>

        <Text style={{textAlign: 'center', fontSize: 16, fontWeight: '600', marginBottom: 12}}>Victory Point Adjustment</Text>
        {teams && teams.map((team, i) => (
          <View key={i} style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly'}}>
            <IconButton icon='minus' onPress={() => removeVpFromTeam(1, team)} />
            <Button color={getTeamColor(team.name)} mode='outlined' style={{width: 200}}>{team.name} - {team.victory_points}</Button>
            <IconButton icon='plus' onPress={() => addVpToTeam(1, team)} />
          </View>
        ))}

        <DataTable style={{marginTop: 24}}>
          <DataTable.Header>
            <DataTable.Title>Team</DataTable.Title>
            <DataTable.Title numeric>Score</DataTable.Title>
            <DataTable.Title numeric>VP</DataTable.Title>
          </DataTable.Header>
          {soloResults.map((result, i) => (
            <DataTable.Row key={i}>
              <DataTable.Cell>{result.team}</DataTable.Cell>
              <DataTable.Cell numeric>{result.score}</DataTable.Cell>
              <DataTable.Cell numeric>{result.vp}</DataTable.Cell>
            </DataTable.Row>
          ))}
        </DataTable>
        {soloResults.length > 0 ? (
          <Button disabled={!soloEnabled} mode='contained' onPress={() => {
            applyResults(soloResults);
            setSoloEnabled(false);
          }}>Apply &quot;Solo&quot; VP</Button>
        ) : (
          <Button mode='outlined' onPress={calculateSolo}>Calculate &quot;Solo&quot; Scores</Button>
        )}

        <DataTable style={{marginTop: 24}}>
          <DataTable.Header>
            <DataTable.Title>Team</DataTable.Title>
            <DataTable.Title numeric>Score</DataTable.Title>
            <DataTable.Title numeric>VP</DataTable.Title>
          </DataTable.Header>
          {teamResults.map((result, i) => (
            <DataTable.Row key={i}>
              <DataTable.Cell>{result.team}</DataTable.Cell>
              <DataTable.Cell numeric>{result.score}</DataTable.Cell>
              <DataTable.Cell numeric>{result.vp}</DataTable.Cell>
            </DataTable.Row>
          ))}
        </DataTable>
        {teamResults.length > 0 ? (
          <Button disabled={!teamEnabled} mode='contained' onPress={() => {
            applyResults(teamResults);
            setTeamEnabled(false);
          }}>Apply &quot;Team&quot; VP</Button>
        ) : (
          <Button mode='outlined' onPress={calculateTeam}>Calculate &quot;Team&quot; Scores</Button>
        )}

        <DataTable style={{marginTop: 24}}>
          <DataTable.Header>
            <DataTable.Title>Team</DataTable.Title>
            <DataTable.Title numeric>Score</DataTable.Title>
            <DataTable.Title numeric>VP</DataTable.Title>
          </DataTable.Header>
          {contestResults.map((result, i) => (
            <DataTable.Row key={i}>
              <DataTable.Cell>{result.team}</DataTable.Cell>
              <DataTable.Cell numeric>{result.score}</DataTable.Cell>
              <DataTable.Cell numeric>{result.vp}</DataTable.Cell>
            </DataTable.Row>
          ))}
        </DataTable>
        {contestResults.length > 0 ? (
          <Button disabled={!contestEnabled} mode='contained' onPress={() => {
            applyResults(contestResults);
            setContestEnabled(false);
          }}>Apply &quot;Contest&quot; VP</Button>
        ) : (
          <Button mode='outlined' onPress={calculateContest}>Calculate &quot;Contest&quot; Scores</Button>
        )}

        <Button style={{marginTop: 48}} mode='outlined' onPress={updateTopTenPlayers}>Update Top Ten</Button>
        <Button style={{marginTop: 48}} mode='outlined' onPress={notifyEveryoneOfScores}>Notify Everyone Scores Updated</Button>
      </View>
      <View style={{height: 200}} />
    </ScrollView>
  );
}