import React from 'react';
import { Portal, Dialog, Button } from 'react-native-paper';
import { fullWidthPadding } from '../../constants/Misc';

export default function AwardDialog({awards, visible, setVisible, toggleAward}) {

  const toggleAnselAward = () => {
    toggleAward('ansel');
  };

  const toggleWowAward = () => {
    toggleAward('wow');
  };

  const toggleWhateverAward = () => {
    toggleAward('whatever');
  };

  const toggleBearUp = () => {
    toggleAward('bearup');
  };

  const toggleBearDown = () => {
    toggleAward('beardown');
  };

  return (
    <Portal>
      <Dialog
        style={{
          width: fullWidthPadding - 48,
          marginBottom: 200,
          alignSelf: 'center',
        }}
        visible={visible}
        onDismiss={() => setVisible(false)}
      >
        <Dialog.Title>
        Add AWARD
        </Dialog.Title>
        <Dialog.Content>
          <Button onPress={toggleAnselAward} mode={awards.includes('ansel') ? 'outlined' : 'contained'} style={{marginBottom: 12}}>#Ansel</Button>
          <Button onPress={toggleWowAward} mode={awards.includes('wow') ? 'outlined' : 'contained'} style={{marginBottom: 12}}>#Wow</Button>
          <Button onPress={toggleWhateverAward} mode={awards.includes('whatever') ? 'outlined' : 'contained'} style={{marginBottom: 12}}>#Whatever</Button>
          <Button onPress={toggleBearUp} mode={awards.includes('bearup') ? 'outlined' : 'contained'} style={{marginBottom: 12}}>Bubba Like</Button>
          <Button onPress={toggleBearDown} mode={awards.includes('beardown') ? 'outlined' : 'contained'}>Bubba Don't Like</Button>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={() => setVisible(false)}>Close</Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
}