import {
  Colors,
  Divider,
  IconButton,
  List,
  Menu,
  Text,
} from "react-native-paper";
import { Platform, SectionList, StyleSheet, View, Clipboard } from "react-native";
/* eslint-disable react/display-name */
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";

import { AuthContext } from "../../context/AuthContext";
import ChallengeCard from "../../components/challenges/ChallengeCard";
import RefreshButton from "../../components/RefreshButton";
import TheHunt from "../../constants/Hunt";
import { getChallengesForDay } from "../../api/challenges";
import { getPostsForUserId } from "../../api/posts";
import { maxColumnWidth, fullWidthPadding } from "../../constants/Misc";
import moment from "moment";

const { startDate } = TheHunt;

// const startingChallenge = {
//   title: "theenlistment",
//   description: "Take a photo of yourself blah blah",
//   category: "solo",
//   day: "∞",
// };

export default function ChallengesScreen({ navigation }) {
  const [challengeSections, setChallengeSections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [postsByUser, setPostsByUser] = useState([]);
  const [currentDay, setCurrentDay] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [dayMenuVisible, setDayMenuVisible] = useState(false);

  const { authContext } = useContext(AuthContext);
  const { user } = authContext;

  const navigateToSubmitScreen = (challenge) => {
    navigation.navigate("SubmitPost", { challenge });
  };

  const updateScreen = () => {
    updateChallenges();
    updatePostsByUser();
  };

  const updateChallenges = () => {
    if (selectedDay === null) return;
    setIsLoading(true);
    getChallengesForDay(selectedDay <= 0 ? 0 : selectedDay)
      .then((data) => {
        const sections = [
          { title: "Solo", data: [] },
          { title: "Team", data: [] },
          { title: "Contest", data: [] },
          { title: "War", data: [] },
        ];
        data.forEach((c) => {
          const sectionIndex = sections.findIndex(
            (s) => s.title.toLowerCase() === c.category
          );
          const curIndex = sections[sectionIndex].data.findIndex(
            (x) => x.title === c.title
          );
          if (curIndex < 0) sections[sectionIndex].data.push(c);
        });
        const filteredSections = sections.filter(section => section.data.length > 0)
        setChallengeSections(filteredSections);
        setIsLoading(false);
      })
      .catch((e) => {
        console.warn("Error fetching challenges ", e);
        Clipboard.setString(e)
        alert('There was an error fetching challenges. Error copied to clipboard.')
        setIsLoading(false);
      });
  };

  const updatePostsByUser = () => {
    setIsLoading(true);
    console.log(user)
    getPostsForUserId(user.id).then((posts) => {
      setPostsByUser(posts);
      setIsLoading(false);
    }).catch(e => {
      console.warn("Error fetching challenges ", e);
      Clipboard.setString(e)
      alert('There was an error updating which challenges you have done. Error copied to clipboard. (Share with the dev)')
      setIsLoading(false);
    })
  };

  const getDayInfo = () => {
    const today = new Date();
    // today.setDate(20)
    let dayDiff = 0
    if (today < startDate) {
      dayDiff = moment(today).diff(startDate, "days");
    } else {
      dayDiff = moment(today).diff(startDate, "days") + 1
    }
    if (dayDiff > TheHunt.length) dayDiff = TheHunt.length;
    setCurrentDay(dayDiff);
    setSelectedDay(dayDiff);
  }

  useEffect(() => {
    getDayInfo();
    const unsubscribe = navigation.addListener('focus', updateScreen);
    return unsubscribe
  }, []);

  useEffect(() => {
    updateScreen();
  }, [currentDay, selectedDay]);

  useLayoutEffect(() => {
    navigation.setOptions({
      title: `${
        selectedDay <= 0 ? "Starter " : `Day ${selectedDay}`
      } Challenges`,
      headerRight: () => (
        <RefreshButton onPress={updateScreen} isLoading={isLoading} />
      ),
      headerLeft: () => (
        <Menu
          visible={dayMenuVisible}
          onDismiss={() => setDayMenuVisible(false)}
          anchor={
            <IconButton
              size={35}
              icon="dots-horizontal"
              onPress={() => setDayMenuVisible(true)}
            />
          }
        >
          {currentDay <= 0 && (
            <Menu.Item title="Other days not available to view" />
          )}
          {currentDay >= 1 && (
            <Menu.Item
              title="View Starters"
              onPress={() => {
                setSelectedDay(-1);
                setDayMenuVisible(false);
              }}
            />
          )}
          {currentDay >= 1 &&
            Array(currentDay)
              .fill(0)
              .map((_, i) => (
                <Menu.Item
                  key={i}
                  onPress={() => {
                    setSelectedDay(i + 1);
                    setDayMenuVisible(false);
                  }}
                  title={`View Day ${i + 1}`}
                />
              ))}
          <Divider />
          <Menu.Item
            onPress={() => setDayMenuVisible(false)}
            title="Close Menu"
          />
        </Menu>
      ),
    });
  }, [navigation, isLoading, dayMenuVisible, selectedDay]);

  return (
    <View style={styles.container}>
      <SectionList
        style={styles.scroll}
        sections={challengeSections}
        showsVerticalScrollIndicator={false}
        renderItem={({ item: challenge, index }) => (
          <ChallengeCard
            key={index}
            challenge={challenge}
            postsByUser={postsByUser}
            onButtonPress={navigateToSubmitScreen}
            currentDay={currentDay}
            selectedDay={selectedDay}
          />
        )}
        renderSectionHeader={({ section: { title } }) => (
          <View
            style={[
              styles.sectionHeader,
              { borderColor: getColorForCategory(title) },
            ]}
          >
            <List.Subheader key={title} style={{ fontSize: 24 }}>
              {title} Challenges
            </List.Subheader>
          </View>
        )}
        ListFooterComponent={() => {
          return Platform.OS === "web" ? (
            <View style={{ height: 300 }} />
          ) : null;
        }}
        ListEmptyComponent={() => (
          <View
            style={{
              height: 250,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{ fontSize: 24, opacity: 0.4, textAlign: "center" }}>
              No challenges here!
            </Text>
          </View>
        )}
      />
    </View>
  );
}

const getColorForCategory = (title) => {
  switch (title?.toLowerCase()) {
    case "solo":
      return Colors.amber200;
    case "team":
      return Colors.lightBlue200;
    case "contest":
      return Colors.green300;
    case "war":
      return Colors.red200;
  }
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    padding: 12,
    paddingBottom: 0
  },
  scroll: {
    // maxWidth: maxColumnWidth,
    width: "100%",
    ...Platform.select({
      web: {
        height: 800,
      },
      ios: {
        height: "100%",
      },
      android: {
        height: "100%",
      },
    }),
  },
  button: {
    height: 36,
    alignSelf: "center",
  },
  sectionHeader: {
    borderRadius: 8,
    borderWidth: 2,
    borderColor: Colors.grey400,
    backgroundColor: Colors.grey900,
    marginBottom: 12,
    alignSelf: 'center',
    width: fullWidthPadding
  },
});
