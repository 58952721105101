import React, { useState, useContext } from 'react';
import {View, Clipboard, StyleSheet} from 'react-native'
import { Card, Paragraph, Colors, Text } from 'react-native-paper';
import FeedCardTitle from './postCard/FeedCardTitle';
import { fullWidthPadding, maxColumnWidth } from '../constants/Misc';
import CommentMenu from './CommentMenu';
import * as WebBrowser from 'expo-web-browser'
import { AuthContext } from '../context/AuthContext';
import { LikeIconButton } from './IconButtons';
import { addNotification } from '../api/notifications';
import { updateLikesForComment } from '../api/comments';
import ParsedText from 'react-native-parsed-text';
import { useNavigation } from '@react-navigation/native';
import moment from 'moment';

export default function CommentCard({comment, removeComment, updateComments, ...props}) {
  const [menuVisible, setMenuVisible] = useState(false)
  const [tempLikes, setTempLikes] = useState({})

  const navigation = useNavigation()
  const {authContext} = useContext(AuthContext)
  const {user} = authContext

  const handleHyperlink = (url) => {
    let link = url
    if (!url.includes('http')) link = `http://${url.trim().toLowerCase()}`
    WebBrowser.openBrowserAsync(link).catch(e => alert(`Couldnt open link: ${link} \n\nError: ${e}`))
  };

  const handleCopy = (content) => {
    Clipboard.setString(content)
    setMenuVisible(false)
  }

  const onTagPress = (username) => {
    navigation.navigate("Profile", { userId: username.slice(1) });
  }

  const deleteComment = () => {
    removeComment(comment)
  }

  const handleCommentLike = (comment) => {
    let newLikes = {};
    const liked_by = comment.liked_by;
    if (liked_by && liked_by.includes(user?.id)) {
      newLikes = { liked_by: liked_by.filter((id) => id !== user.id) };
    } else {
      newLikes = { liked_by: [...liked_by, user.id] };
      if (user.id !== comment.author.id) {
        const msg = `${user.first_name} liked your comment`;
        addNotification(comment.author.id, comment.on_post, msg);
      }
    }
    setTempLikes(newLikes)
    updateLikesForComment(comment.id, newLikes);
    updateComments();
  };

  const handleLikeLongPress = () => {
    props.setLikesForComment(comment.liked_by)
    props.setShowLikesDialog(true)
  }

  return (
    <Card style={styles.card}>
            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: 'space-between' }}>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <FeedCardTitle player={comment.author} outline />
                <Text style={{fontSize: 12, marginLeft: 12, opacity: .4}}>{moment(comment.created_at).fromNow()}</Text>
              </View>
              {(comment.author?.id === user?.id || user?.admin) && (
                <CommentMenu
                  visible={menuVisible}
                  setVisible={setMenuVisible}
                  comment={comment}
                  handleCopy={handleCopy}
                  removeComment={deleteComment}
                />
              )}
            </View>
            <Card.Content
              style={styles.content}
            >
                <Paragraph style={styles.contentText}>
                  <ParsedText
                    parse={
                      [
                        {type: 'url', style: styles.linkStyle, onPress: handleHyperlink},
                        {pattern: /@[a-zA-Z^\S]+/, style: {color: Colors.blue500}, onPress: onTagPress},
                      ]
                    }
                  >
                    {comment.content}
                  </ParsedText>
                </Paragraph>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <LikeIconButton
                  liked={comment.liked_by?.includes(user?.id) || tempLikes?.liked_by?.includes(user?.id)}
                  onPress={() => handleCommentLike(comment)}
                  onLongPress={handleLikeLongPress}
                />
                <Text
                  style={{
                    color: Colors.red700,
                    fontSize: 18,
                    fontWeight: "700",
                    marginLeft: -6,
                  }}
                >
                  {comment.liked_by?.length < tempLikes.liked_by?.length ? tempLikes.liked_by?.length : comment.liked_by?.length}
                </Text>
              </View>
            </Card.Content>
          </Card>
  )
}


const styles = StyleSheet.create({
  card: {
    width: fullWidthPadding,
    maxWidth: maxColumnWidth,
    marginHorizontal: 12,
    marginBottom: 6,
    backgroundColor: Colors.grey900
  },
  content: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: 'center',
  },
  contentText: {
    fontSize: 16,
    maxWidth: fullWidthPadding - 90,
  },
  linkStyle: {
    color: Colors.purple200,
    textDecorationLine: "underline",
  },
});
