import AnnouncementsScreen from "../screens/misc/AnnouncementsScreen";
import CreateAnnouncementScreen from "../screens/CreateAnnouncementScreen";
import MiscScreen from "../screens/misc/MiscScreen";
import MyPosts from "../screens/misc/MyPosts";
import PlayerListScreen from "../screens/misc/PlayerListScreen";
import PostDetailScreen from "../screens/feed/PostDetailScreen";
import PostsForUserScreen from "../screens/PostsForUserScreen";
import ProfileScreen from "../screens/ProfileScreen";
import React from "react";
import ScoresScreen from "../screens/ScoresScreen";
import { createStackNavigator } from "@react-navigation/stack";
import TeamProfileScreen from "../screens/misc/TeamProfileScreen";
import TeamFeedScreen from "../screens/misc/TeamFeedScreen";
import TheHunt from "../constants/Hunt";
import ContendersScreen from "../screens/misc/contenders/ContendersScreen";
import ContenderListScreen from "../screens/misc/contenders/ContenderListScreen";
import AwardListScreen from "../screens/misc/awards/AwardListScreen";

const Stack = createStackNavigator();

export default function MiscStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Misc"
        component={MiscScreen}
        options={{ title: `#TheHunt (v${TheHunt.version})` }}
      />
      <Stack.Screen
        name="MyPosts"
        component={MyPosts}
        options={{ title: "My Posts" }}
      />
      <Stack.Screen
        name="CreateAnnouncement"
        component={CreateAnnouncementScreen}
        options={{ title: "Create Announcement" }}
      />
      <Stack.Screen
        name="AnnouncementList"
        component={AnnouncementsScreen}
        options={{ title: "Announcements" }}
      />
      <Stack.Screen
        name="PostDetail"
        component={PostDetailScreen}
        options={({route}) => ({
          title: `${route.params.post?.challenge?.includes('announcement') ? 'Announcement' : 'Post'} Detail`,
        })}
      />
      <Stack.Screen
        name="PostsForUser"
        component={PostsForUserScreen}
        options={({ route }) => ({
          title: `Posts by ${route?.params?.user?.full_name || "User"}`,
        })}
      />
      <Stack.Screen
        name="PlayerList"
        component={PlayerListScreen}
        options={{
          title: "Player List",
        }}
      />
      <Stack.Screen
        name="Profile"
        component={ProfileScreen}
        options={({ route }) => ({
          title: route?.params?.user?.full_name || "Profile",
        })}
      />
      <Stack.Screen
        name="Scores"
        component={ScoresScreen}
        options={{
          title: "Calculate Scores",
        }}
      />
      <Stack.Screen
        name="TeamProfile"
        component={TeamProfileScreen}
        options={({route}) => ({
          title: `Team ${route.params.team.name.charAt(0).toUpperCase()}${route.params.team.name.slice(1)} Profile`,
        })}
      />
      <Stack.Screen
        name="TeamFeed"
        component={TeamFeedScreen}
        options={({route}) => ({
          title: `Team ${route.params.team.name.charAt(0).toUpperCase()}${route.params.team.name.slice(1)} Feed`,
        })}
      />
      <Stack.Screen
        name="AwardContenders"
        component={ContendersScreen}
        options={{
          title: `Contenders`,
          headerBackTitle: 'Back'
        }}
      />
      <Stack.Screen
        name="ContenderList"
        component={ContenderListScreen}
        options={({route}) => ({
          title: `${route.params.title} Contenders`,
          headerBackTitle: 'Back'
        })}
      />
      <Stack.Screen
        name="AwardList"
        component={AwardListScreen}
        options={({route}) => ({
          title: `${route.params.title} Awards`,
          headerBackTitle: 'Back'
        })}
      />
    </Stack.Navigator>
  );
}
