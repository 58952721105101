/* eslint-disable react/display-name */
import React, { useState, useEffect, useContext } from 'react';
import { fetchAllPosts, getPostById, getPostsForDay, getUnscoredPosts } from '../../api/posts';
import RefreshButton from '../../components/RefreshButton';
import Feed from '../../components/feed/Feed';
import { Banner, Menu, Divider, Text, Colors } from 'react-native-paper';
import { AuthContext } from '../../context/AuthContext';
import { getUnreadAnnouncementForUser, markAllAnnouncementsAsReadForUser, getAnnouncementById } from '../../api/announcements';
import { Notifications } from 'expo';
import * as Permissions from 'expo-permissions';
import Constants from 'expo-constants';
import { Platform, View } from 'react-native';
import { addPushTokenToUser } from '../../api/users';
import TheHunt from '../../constants/Hunt';
import moment from 'moment'
import { FilterButton } from '../../components/IconButtons';
import * as Network from 'expo-network';
import { NetworkContext } from '../../context/NetworkContext';
import { SCREEN_WIDTH } from '../../constants/Layout';

export default function FeedScreen({navigation}) {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [announcementBannerVisible, setBannerVisible] = useState(false);
  const [unreadAnnouncement, setUnreadAnnouncement] = useState(null);
  const [filterMenuVisible, setFilterMenuVisible] = useState(false);
  const [currentDay, setCurrentDay] = useState(-1)
  const [selectedDay, setSelectedDay] = useState(null)
  const [viewUnscored, setViewUnscored] = useState(false)

  const {authContext, setUser} = useContext(AuthContext);
  const {connected, setConnected} = useContext(NetworkContext)
  const {user} = authContext;

  let _pushNotifListener = null;

  const updateAll = () => {
    getDayInfo();
    checkForUnreadAnnouncements();
    updatePosts();
    checkNetwork();
  };

  const checkNetwork = () => {
    Network.getNetworkStateAsync().then(state => {
      setConnected(state.isConnected)
    })
  }

  const checkForUnreadAnnouncements = () => {
    getUnreadAnnouncementForUser(user.id).then(a => {
      if (a) {
        setUnreadAnnouncement(a);
        setBannerVisible(true);
      }
    });
  };

  const markAllAnnouncementsAsRead = () => {
    setBannerVisible(false);
    markAllAnnouncementsAsReadForUser(user.id);
  };

  const viewAnnouncement = () => {
    markAllAnnouncementsAsRead();
    navigation.navigate('PostDetail', {post: unreadAnnouncement});
  };

  const updatePosts = () => {
    if (selectedDay === null) return;
    setIsLoading(true);
    if (viewUnscored) {
      setPosts([])
      getUnscoredPosts().then(data => {
        setIsLoading(false);
        setPosts(data);
      }).catch(err => {
        console.warn('Couldnt get feed :( unscored', err);
        setIsLoading(false);
      });
    } else if (selectedDay >= 0) {
      getPostsForDay(selectedDay).then(data => {
        setIsLoading(false);
        setPosts(data);
      }).catch(err => {
        console.warn('Couldnt get feed :( for selected day', err);
        setIsLoading(false);
      });
    } else {
      fetchAllPosts().then(data => {
        setIsLoading(false);
        setPosts(data);
      }).catch(err => {
        console.warn('Couldnt get feed :( all posts', err);
        setIsLoading(false);
      });
    }
  };

  const registerForPushNotifications = async () => {
    if (Constants.isDevice) {
      const { status: existingStatus } = await Permissions.getAsync(Permissions.NOTIFICATIONS);
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        alert('Failed to get push token for push notification!');
        return;
      }
      const token = await Notifications.getExpoPushTokenAsync();
      // todo: add push token to user
      addPushTokenToUser(user.id, token);
      setUser({...user, push_token: token});
    } else {
      // alert('Must use physical device for Push Notifications');
    }
    
    if (Platform.OS === 'android') {
      Notifications.createChannelAndroidAsync('default', {
        name: 'default',
        sound: true,
        priority: 'max',
        vibrate: [0, 250, 250, 250],
      });
    }
  };

  const handleNotification = notification => {
    if (!notification.data.postId) return;
    if (notification.data.postId.includes('announcement')) {
      getAnnouncementById(notification.data.postId).then(post => {
        markAllAnnouncementsAsRead();
        navigation.navigate('PostDetail', {post});
      });
    } else {
      getPostById(notification.data.postId).then(post => {
        navigation.navigate('PostDetail', {post});
      });
    }
  };

  const getDayInfo = () => {
    const today = new Date();
    // today.setDate(20)
    let dayDiff = 0
    if (today < TheHunt.startDate) {
      dayDiff = moment(today).diff(TheHunt.startDate, "days");
    } else {
      dayDiff = moment(today).diff(TheHunt.startDate, "days") + 1
    }
    if (dayDiff > TheHunt.length) dayDiff = TheHunt.length;
    setCurrentDay(dayDiff);
    setSelectedDay(dayDiff);
  }

  useEffect(() => {
    updateAll()

    if (Platform.OS !== 'web') {
      registerForPushNotifications();
      _pushNotifListener = Notifications.addListener(handleNotification);
    }

    const unsubscribe = navigation.addListener('focus', updateAll);
    return () => {
      if (_pushNotifListener) _pushNotifListener.remove();
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (selectedDay !== null) {
      updatePosts()
      setFilterMenuVisible(false)
    }
  }, [selectedDay, viewUnscored])

  React.useLayoutEffect(() => {
    navigation.setOptions({
      title: `Feed  (v${TheHunt.version})`,
      headerRight: () => (
          <RefreshButton onPress={updateAll} isLoading={isLoading} />
      ),
      headerLeft: () => (
        <Menu
          visible={filterMenuVisible}
          onDismiss={() => setFilterMenuVisible(false)}
          anchor={
            <FilterButton onPress={() => setFilterMenuVisible(true)} />
          }
        >
          {user?.admin && <Menu.Item title="View unscored posts" onPress={() => setViewUnscored(true)} />}
          {currentDay >= 1 && Array(currentDay).fill(0).map((_, i) => (
            <Menu.Item
              key={i}
              onPress={() => {
                setSelectedDay(i + 1)
                setFilterMenuVisible(false)
              }}
              title={`View Posts on Day ${i+1}`}
            />
          ))}
          <Menu.Item title="View all posts" onPress={() => {
            setSelectedDay(-1)
            setViewUnscored(false)
          }} />
          <Divider />
          <Menu.Item onPress={() => setFilterMenuVisible(false)} title="Close Menu" />
        </Menu>
      )
    });
  }, [navigation, isLoading, filterMenuVisible]);

  return (
    <>
      {!connected && (
        <View style={{height: 20, width: SCREEN_WIDTH, backgroundColor: Colors.red500, justifyContent: 'center', alignItems: 'center'}}>
          <Text>No Internet Connection</Text>
        </View>
      )}
      <Banner
        visible={announcementBannerVisible}
        actions={[
          {
            label: 'Dismiss',
            onPress: markAllAnnouncementsAsRead,
          },
          {
            label: 'View Now',
            mode: 'contained',
            onPress: viewAnnouncement,
          },
        ]}
      >
        There's a new announcement! You can view old announcements on the "Misc" tab.
      </Banner>
      <Feed posts={posts} updateScreen={updatePosts} />
    </>
  );
}