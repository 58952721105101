import {firestore} from './firebase';

export const getChallengesForDay = (day) => {
  return new Promise((resolve, reject) => {
    let challenges = [];
    firestore.collection('challenges').where('day', '==', day).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        challenges.push(doc.data())
      });
      resolve(challenges.sort((a, b) => a.order - b.order));
    }).catch(e => reject(e));
  });
};

export const getChallengeById = (challengeId) => {
  return new Promise((resolve, reject) => {
    firestore.collection('challenges').doc(challengeId).get()
      .then(doc => {
        if (doc.exists) {
          const user = {...doc.data(), id: challengeId};
          resolve(user)
        } else {
          reject(`doc does not exist for challenge id "${challengeId}"`);
        }
      })
      .catch(reject);
  });
};