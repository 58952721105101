import * as firebase from 'firebase';
import '@firebase/firestore';

// Initialize Firebase
const firebaseConfig = {
  apiKey: 'AIzaSyDsbvI8gC9CXLXxt3OPPOAtraZCyGSJC4Y',
  authDomain: 'the-hunt-backend.firebaseapp.com',
  projectId: 'the-hunt-backend',
  storageBucket: 'the-hunt-backend.appspot.com',
};

firebase.initializeApp(firebaseConfig);

export const storage = firebase.storage();
export const firestore = firebase.firestore();