import { firestore } from './firebase';

export const getAllTeams = () => {
  return new Promise((resolve, reject) => {
    let teams = [];
    firestore
      .collection('teams')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => teams.push(doc.data()));
        resolve(
          teams
            .sort((a, b) => a.order - b.order)
            .filter((t) => t.name !== 'lion turtle')
        );
      })
      .catch((e) => reject(e));
  });
};

export const getPlayersOnTeam = (team) => {
  return new Promise((resolve, reject) => {
    const players = [];
    firestore
      .collection('users')
      .where('team', '==', team.toLowerCase())
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => players.push({...doc.data(), id: doc.id}));
        resolve(players);
      })
      .catch((e) => reject(e));
  });
};