import React, { useState, useContext } from 'react';
import { uploadMediaToFirebase } from '../api/storage';
import { uploadPost } from '../api/posts';
import { AuthContext } from '../context/AuthContext';
import PostCreator from '../components/PostCreator';
import { Clipboard} from 'react-native';
import {ProgressBar, Colors, Text} from 'react-native-paper';

export default function SubmitPostScreen({navigation, route}) {
  const [isSubmittingPost, setIsSubmittingPost] = useState(false);
  const [error, setError] = useState(null);
  const [percentUploaded, setPercentUploaded] = useState(0);

  const {authContext} = useContext(AuthContext);
  const {user} = authContext;
  const challenge = route.params.challenge;

  const onUploadError = e => {
    // Handle unsuccessful uploads
    console.log('Failed uploading to firebase storage: ', e);
    Clipboard.setString(`${e}`);
    setError('Failed to upload media to Firebase. Error copied to clipboard. You can report this error to your captain.');
  };

  const onUploadUpdate = (snapshot) => {
    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    setPercentUploaded(progress);
  };

  const upload = async (uri) => {
    return await uploadMediaToFirebase(uri, `${user.id}-${challenge.title}-${(new Date()).getTime()}-${Math.random()*10000}`, onUploadError, onUploadUpdate);
  };

  const submitPost = async (singlePhoto, album, videoUri, postCaption) => {
    setIsSubmittingPost(true);

    const attachments = [];

    let singlePhotoUrl = null;
    if (singlePhoto) {
      singlePhotoUrl = await upload(singlePhoto.uri);
      attachments.push({type: 'photo', url: singlePhotoUrl});
    }

    if (album.length > 0) {
      const promises = [];
      album.forEach(uri => {
        promises.push(upload(uri));
      });
      const photos = await Promise.all(promises);
      attachments.push({type: 'album', photos});
    }

    if (videoUri) {
      const url = await upload(videoUri);
      attachments.push({type: 'video', videoUrl: url});
    }

    await uploadPost(user, attachments, postCaption, challenge.title);
    setIsSubmittingPost(false);
    navigation.goBack();
    navigation.navigate('Feed');
  };

  return (
    <>
      <PostCreator error={error} challenge={challenge} submitPost={submitPost} isSubmitting={isSubmittingPost} />
      <Text style={{alignSelf: 'flex-end', fontSize: 16, opacity: .4}}>{Math.ceil(percentUploaded)}%</Text>
      <ProgressBar progress={percentUploaded / 100} color={Colors.purple400} />
    </>
  );
}