import React from 'react';
import { Chip, Avatar, Colors } from 'react-native-paper';
import { getTeamColor } from '../../utilities/getColorForTeam';
import { View, StyleSheet } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { fullWidthPadding } from '../constants/Misc';

export default function PlayerChip({player, ...props}) {

  const navigation = useNavigation()

  const onUserPress = () => {
    if (props.onPress) props.onPress()
    else {
      navigation.navigate("Profile", { user: player });
    }
  }

  const renderAvatar = () => {
    return player?.avatar && player?.avatar.length > 0 ? (
      <View>
        <Avatar.Image size={24} source={{ uri: player?.avatar }} />
      </View>
    ) : (
      <View style={styles.iconAvatar} >
       <MaterialCommunityIcons
          size={20}
          name="account"
          color={Colors.grey600}
        />
      </View>
 
    )
  }

  return (
    <View style={[props.style ? props.style : {}]}>
      <Chip style={[{backgroundColor: getTeamColor(player?.team)}]} avatar={renderAvatar()} onPress={onUserPress}>{player?.full_name}</Chip>
    </View>
  )
}


const styles = StyleSheet.create({
  iconAvatar: {
    backgroundColor: Colors.grey800,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center'
  },
});