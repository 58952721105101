/* eslint-disable react/display-name */
import React, { useContext, useEffect, useState } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { AuthContext } from '../context/AuthContext';
import BottomTabNavigator from './BottomTabNavigator';
import AuthScreen from '../screens/AuthScreen';
import { getUserDataFromDevice } from '../api/storage';
import SplashScreen from '../screens/SplashScreen';
import TheHunt from '../constants/Hunt';

const Stack = createStackNavigator();

export default function AppNavigator() {
  const {authContext, setUser} = useContext(AuthContext);
  const [loadedAuth, setLoadedAuth] = useState(false);

  useEffect(() => {
    getUserDataFromDevice().then(userData => {
      if (userData) setUser(userData);
      setLoadedAuth(true);
    });
  }, []);

  return (
    <Stack.Navigator>
      {loadedAuth ? (
        authContext.user ? (
          <Stack.Screen name="Root" component={BottomTabNavigator} />
        ) : (
          <Stack.Screen name="Auth" component={AuthScreen} options={{title: `#TheHunt2020 Sign In (v${TheHunt.version})`}} />
        )
      ) : (
        <Stack.Screen name='Splash' component={SplashScreen} options={{title: ''}} />
      )}
    
    </Stack.Navigator>
  );
}