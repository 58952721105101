import React from 'react';
import { Portal, Dialog, Button, Colors } from 'react-native-paper';
import { fullWidthPadding } from '../../constants/Misc';

export default function ContenderDialog({contenderFor, visible, setVisible, toggleContenderFor}) {

  const toggleAnselContender = () => {
    toggleContenderFor('ansel');
  };

  const toggleWowContender = () => {
    toggleContenderFor('wow');
  };

  const toggleWhateverContender= () => {
    toggleContenderFor('whatever');
  };

  return (
    <Portal>
      <Dialog
        style={{
          width: fullWidthPadding - 48,
          marginBottom: 200,
          alignSelf: 'center',
        }}
        visible={visible}
        onDismiss={() => setVisible(false)}
      >
        <Dialog.Title>
        Add CONTENDER
        </Dialog.Title>
        <Dialog.Content>
          <Button onPress={toggleAnselContender} mode={contenderFor?.includes('ansel') ? 'outlined' : 'contained'} style={{marginBottom: 12}} color={Colors.green500}>#Ansel</Button>
          <Button onPress={toggleWowContender} mode={contenderFor?.includes('wow') ? 'outlined' : 'contained'} style={{marginBottom: 12}} color={Colors.green500}>#Wow</Button>
          <Button onPress={toggleWhateverContender} mode={contenderFor?.includes('whatever') ? 'outlined' : 'contained'} color={Colors.green500}>#Whatever</Button>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={() => setVisible(false)}>Close</Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
}