import React from 'react';
import {StyleSheet, View} from 'react-native';
import {Text, Colors} from 'react-native-paper';

export const AnselAward = ({style = {}}) => (
  <View style={style}>
    <View style={{alignItems: 'center', marginRight: 12}}>
      <View style={styles.circle}>
        <View style={styles.icon}>
          <Text style={styles.iconSize}>📸</Text>
        </View>
      </View>
      <Text style={styles.label}>#ansel</Text>
    </View>
  </View>
);

export const WowAward = ({style = {}}) => (
  <View style={style}>
    <View style={{alignItems: 'center', marginRight: 12}}>
      <View style={styles.circle}>
        <View style={styles.icon}>
          <Text style={styles.iconSize}>🤩</Text>
        </View>
      </View>
      <Text style={styles.label}>#wow</Text>
    </View>
  </View>
);

export const WhateverAward = ({style = {}}) => (
  <View style={style}>
    <View style={{alignItems: 'center', marginRight: 12}}>
      <View style={styles.circle}>
        <View style={styles.icon}>
          <Text style={[styles.iconSize, {fontSize: 31}]}>🤷‍♂️</Text>
        </View>
      </View>
      <Text style={styles.label}>#whatever</Text>
    </View>
  </View>
);

export const BearUpAward = ({style = {}}) => (
  <View style={style}>
    <View style={{alignItems: 'center', marginRight: 12}}>
      <View style={[styles.circle, {width: awardSize * 2, borderColor: Colors.green500}]}>
        <View style={[styles.icon, {width: awardSize * 2}]}>
          <Text style={[styles.iconSize, {fontSize: 31}]}>🐻👍🏼</Text>
        </View>
      </View>
      <Text style={styles.label}>Bubba Yum</Text>
    </View>
  </View>
);

export const BearDownAward = ({style = {}}) => (
  <View style={style}>
    <View style={{alignItems: 'center', marginRight: 12}}>
      <View style={[styles.circle, {width: awardSize * 2, borderColor: Colors.red500}]}>
        <View style={[styles.icon, {width: awardSize * 2}]}>
          <Text style={[styles.iconSize, {fontSize: 31}]}>🐻👎🏼</Text>
        </View>
      </View>
      <Text style={styles.label}>Bubba Bleh</Text>
    </View>
  </View>
);

const awardSize = 47;
const styles = StyleSheet.create({
  circle: {
    padding: 6,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
    width: awardSize,
    height: awardSize,
    borderWidth: 4,
    borderColor: Colors.yellow600,
    backgroundColor: Colors.black
  },
  icon: {
    width: awardSize,
    height: awardSize,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconSize: {
    fontSize: 27,
    marginLeft: 2
  },
  label: {
    fontSize: 12,
    opacity: .6,
    marginTop: 2
  }
});