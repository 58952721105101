import React, { useState, useEffect } from 'react';
import Feed from '../components/feed/Feed';
import RefreshButton from '../components/RefreshButton';
import { getPostsForUserId } from '../api/posts';

export default function PostsForUserScreen({navigation, route}) {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {user} = route.params;

  const updatePosts = () => {
    setIsLoading(true);
    getPostsForUserId(user.id).then(data => {
      setIsLoading(false);
      setPosts(data);
    }).catch(err => {
      console.warn('Couldnt get feed :( ', err);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    updatePosts();
  }, []);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      // eslint-disable-next-line react/display-name
      headerRight: () => (
        <RefreshButton onPress={updatePosts} isLoading={isLoading} />
      ),
    });
  }, [navigation, isLoading]);

  return <Feed posts={posts} updateScreen={updatePosts} />;
}
