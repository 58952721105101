import React from 'react';
import { View, StyleSheet, TouchableHighlight } from 'react-native';
import {Text, Colors} from 'react-native-paper';
import {Entypo} from '@expo/vector-icons';

export default function ContenderScreen({navigation}){

  const navToContenderList = (award) => {
    const formattedAward = `${award.charAt(1).toLowerCase()}${award.slice(2)}`;
    navigation.navigate('ContenderList', {title: award, award: formattedAward});
  };

  return (
    <View>
      <TouchableHighlight onPress={() => navToContenderList('#Ansel')} underlayColor={Colors.grey900}>
        <View style={styles.rowItem}>
          <Text style={styles.rowText}>#Ansel</Text>
          <Entypo name='chevron-thin-right' color={Colors.grey200} size={20} />
        </View>
      </TouchableHighlight>
      <TouchableHighlight onPress={() => navToContenderList('#Wow')} underlayColor={Colors.grey900}>
        <View style={styles.rowItem}>
          <Text style={styles.rowText}>#Wow</Text>
          <Entypo name='chevron-thin-right' color={Colors.grey200} size={20} />
        </View>
      </TouchableHighlight>
      <TouchableHighlight onPress={() => navToContenderList('#Whatever')} underlayColor={Colors.grey900}>
        <View style={styles.rowItem}>
          <Text style={styles.rowText}>#Whatever</Text>
          <Entypo name='chevron-thin-right' color={Colors.grey200} size={20} />
        </View>
      </TouchableHighlight>
    </View>
  );
}

const styles = StyleSheet.create({
  rowItem: {
    padding: 24,
    borderBottomColor: Colors.grey600,
    borderBottomWidth: .5,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  rowText: {
    fontSize: 18,
    fontWeight: '700'
  }
});