import { DEFAULT_IMG, fullWidthPadding } from '../constants/Misc';
import { Platform, TouchableHighlight, View, Image } from 'react-native';

import CachedImage from './CachedImage';
import React from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';


const imageWidth = fullWidthPadding - 2;

export default function HuntImage(props) {
  const navigation = useNavigation();
  const route = useRoute();

  const navToImageModal = () => {
    if (props.album || (props.onPress && route.name !== 'PostDetail')) {
      props.onPress();
      return;
    }
    if (props.disableLightbox) return;
    if (Platform.OS !== 'web') {
      navigation.navigate('ImageViewer', { imgSource: props.source });
    }
  };

  const renderImage = () => {
    return (
      <TouchableHighlight onPress={navToImageModal}>
        <View>
          {Platform.OS === 'android' ? (
            <Image
              source={props.source}
              style={{ width: imageWidth, height: imageWidth }}
              resizeMode='contain'
              defaultSource={DEFAULT_IMG}
            />
          ) : (
            <CachedImage
              source={props.source}
              style={{ width: imageWidth, height: imageWidth }}
              resizeMode='contain'
              defaultSource={DEFAULT_IMG}
            />
          )}

        </View>
      </TouchableHighlight>
    );
  };

  return <View style={{ height: imageWidth }}>{renderImage()}</View>;
}
