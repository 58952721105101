import { getChallengesForDay, getChallengeById } from './challenges';
import { getPostsForChallenge, getPostsForUserId } from './posts';
import { firestore } from './firebase';
import { addNotification } from './notifications';
import { fetchAllUsers, getUserById } from './users';
import { getAllTeams } from './teams';
import { convertAuthorReference } from './announcements';
import { Clipboard } from 'react-native';

export const calculateScoresForDay = async (day, category) => {
  let challenges = await getChallengesForDay(day);
  const teams = await getAllTeams();
  const vpForSolo = await getVpForCategory('solo');
  const vpForContest = await getVpForCategory('contest');
  const vpForTeam = await getVpForCategory('team');

  challenges = challenges.filter(c => c.category === category);
  const postPromises = [];
  challenges.forEach(challenge => {
    postPromises.push(getPostsForChallenge(challenge.title));
  });
  const postsForChallenges = await Promise.all(postPromises);

  const postsByUsers = {};
  postsForChallenges.forEach(postArrayForSingleChallenge => {
    postArrayForSingleChallenge.forEach(post => {
      if (postsByUsers[post.author.id]) {
        postsByUsers[post.author.id].push(post);
      } else {
        postsByUsers[post.author.id] = [post];
      }
    });
  });

  const results = [];
  teams.forEach(team => {
    results.push({team: team.name, score: 0, vp: 0});
  });

  // sort and get top 3 posts (others are used for gladiator)
  // then add the total to the result for the team 
  Object.keys(postsByUsers).forEach(key => {
    const userPostArray = postsByUsers[key];
    postsByUsers[key] = userPostArray.sort((a, b) => b.score - a.score);
    if (category === 'solo') {
      postsByUsers[key] = userPostArray.slice(0, 3);
    }

    let totalScoreForUser = 0;
    postsByUsers[key].forEach(post => totalScoreForUser += post.score);

    const team = postsByUsers[key][0].author.team;
    const resultIndex = results.findIndex(r => r.team === team);
    if (resultIndex >= 0) {
      results[resultIndex].score += totalScoreForUser;
    }
  });


  results.sort((a, b) => b.score - a.score);
  results.forEach((_, i) => {
    if (category === 'solo') {
      results[i].vp = vpForSolo[i];
    } else if (category === 'contest') {
      results[i].vp = vpForContest[i];
    } else if (category === 'team') {
      results[i].vp = vpForTeam[i];
    }
  });

  return results;
};

export const addVpForTeam = (vp, team) => {
  return firestore.collection('teams').doc(team.name).update({victory_points: team.victory_points + vp});
};

export const removeVpForTeam = (vp, team) => {
  return firestore.collection('teams').doc(team.name).update({victory_points: team.victory_points - vp});
};

export const scorePost = (score, postId) => {
  return firestore.collection('posts').doc(postId).update({score, scored: true});
};

export const addAwardToPost = (award, post) => {
  let notifMessage = '';
  if (award === 'bearup') {
    notifMessage = 'The mighty Bubba thinks your post looks tasty...';
  } else if (award === 'beardown') {
    notifMessage = 'The mighty Bubba looks disgusted by your post...';
  } else {
    notifMessage = `You received the #${award} award on your post!`;
    firestore.collection('users').doc(post.author.id).update({gladiator_score: post.author.gladiator_score + 30});
  }
  addNotification(post.author.id, post.id, notifMessage);
  removeContenderFromPost(award, post);
  return firestore.collection('posts').doc(post.id).update({awards: [...post.awards, award]});
};

export const removeAwardFromPost = (award, post) => {
  return firestore.collection('posts').doc(post.id).update({awards: post.awards.filter(a => a !== award)});
};

const getVpForCategory = (category) => {
  return new Promise((resolve, reject) => {
    firestore.collection('vp').doc(category).get()
      .then(doc => {
        if (doc.exists) {
          resolve(doc.data().vp_values);
        } else {
          reject([6, 4, 3, 1]);
        }
      });
  });
};

const calculateTopTen = async () => {
  const players = await fetchAllUsers();
  let gladiatorScorePromises = [];
  players.forEach(player => {
    gladiatorScorePromises.push(calculateGladiatorScoreForUser(player));
  });
  const scores = await Promise.all(gladiatorScorePromises);

  const gladiatorScores = [];
  scores.forEach((score, i) => {
    gladiatorScores.push({
      player: players[i],
      score
    });
    firestore.collection('users').doc(players[i].id).update({gladiator_score: score});
  });
  gladiatorScores.sort((a,b) => b.score - a.score);
  return gladiatorScores.slice(0, 10);
};

const calculateGladiatorScoreForUser = async (user) => {
  const posts = await getPostsForUserId(user.id);
  let score = 0;
  const challengePromises = [];
  posts.forEach(post => {
    if (post.awards.length > 0) {
      score += post.awards.length * 30;
    }
    challengePromises.push(getChallengeById(post.challenge));
  });
  const challenges = await Promise.all(challengePromises);
  challenges.forEach(challenge => {
    const post = posts.find(p => p.challenge === challenge.title);
    if (post.scored && challenge.category !== 'team') {
      score += post.score;
    }
  });
  return score;
};

export const getGladiatorScoreForUser = async (user) => {
  const player = await getUserById(user.id);
  return player.gladiator_score || 0;
};

export const updateTopTen = async () => {
  const topTen = await calculateTopTen();
  await firestore.collection('users').doc('topten').delete();
  firestore.collection('users').doc('topten').set({data: topTen, updated_at: (new Date()).valueOf()}).then(() => {
    alert('Top Ten updated.');
  });
};

export const getTopTen = () => {
  return new Promise((resolve, reject) => {
    firestore.collection('users').doc('topten').get()
      .then(doc => {
        if (doc.exists) {
          resolve(doc.data());
        } else {
          console.warn('Could not get top ten');
          reject(null);
        }
      });
  });
};

export const getContendersForAward = (award) => {
  if (!award) return;
  let posts = [];
  return new Promise((resolve, reject) => {
    firestore.collection('posts')
      .where('contender_for', 'array-contains', award)
      .orderBy('created_at', 'desc')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach((doc) => {
          posts.push({ ...doc.data(), id: doc.id });
        });
        convertAuthorReference(posts).then((authors) => {
          posts.forEach((post, i) => {
            post.author = authors[i].data();
          });
          resolve(posts);
        });
      }).catch(e => {
        console.warn(e);
        Clipboard.setString(e);
        alert('Issue getting posts - error copied to clipboard.');
        resolve([]);
      });
  });
};

export const addContenderToPost = (contender, post) => {
  let thing = post.contender_for ? post.contender_for : [];
  return firestore.collection('posts').doc(post.id).set({contender_for: [...thing, contender]}, {merge: true});
};

export const removeContenderFromPost = (contender, post) => {
  if (post.contender_for && post.contender_for.includes(contender)) {
    return firestore.collection('posts').doc(post.id).set({contender_for: post.contender_for.filter(c => c !== contender)}, {merge: true});
  }
};

export const getPostsWithAward = (award) => {
  if (!award) return;
  let posts = [];
  return new Promise((resolve, reject) => {
    firestore.collection('posts')
      .where('awards', 'array-contains', award)
      .orderBy('created_at', 'desc')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach((doc) => {
          posts.push({ ...doc.data(), id: doc.id });
        });
        convertAuthorReference(posts).then((authors) => {
          posts.forEach((post, i) => {
            post.author = authors[i].data();
          });
          resolve(posts);
        });
      }).catch(e => {
        console.warn(e);
        Clipboard.setString(e);
        alert('Issue getting posts - error copied to clipboard.');
        resolve([]);
      });
  });
};
