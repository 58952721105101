import { getUserRefById, getUserById } from './users';
import { firestore } from './firebase';
import { notifyEveryone } from './notifications';

const sortAnnouncementsBy = (a, b) => (b.created_at - a.created_at);

export const fetchAllAnnouncements = () => {
  return new Promise((resolve, reject) => {
    let announcements = [];
    firestore.collection('announcements').get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        announcements.push({...doc.data(), id: doc.id});
      });
      convertAuthorReference(announcements).then(authors => {
        announcements.forEach((a, i) => {
          a.author = authors[i].data();
        });
        resolve(announcements.sort(sortAnnouncementsBy));
      });
    }).catch(e => reject(e));
  });
};

export const uploadAnnouncement = async (authorId, attachments, postCaption) => {
  const author = await getUserRefById(authorId);
  const data = {
    author, 
    attachments,
    content: postCaption,
    challenge: 'announcement',
    liked_by: [],
    created_at: (new Date()).getTime(),
    read_by: [authorId]
  };
  const announcementId = `${authorId}-announcement-${data.created_at}`
  const response = await firestore.collection('announcements').doc(announcementId).set(data);
  const authorData = await getUserById(authorId)
  notifyEveryone(announcementId, `${authorData.first_name} posted an announcement`, 'From The Lion Turtle');
  return response;
};

export const updateLikesForAnnouncement = (announcementId, likes) => {
  firestore.collection('announcements').doc(announcementId).update(likes);
};

export const convertAuthorReference = announcements => {
  const promises = [];
  announcements.forEach(a => promises.push(a.author.get()));
  return Promise.all(promises);
};

export const deleteAnnouncement = async (postId) => {
  firestore.collection('announcements').doc(postId).delete();
  
  const commentsRef = firestore.collection('comments');
  commentsRef.where('on_post', '==', postId).get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        commentsRef.doc(doc.id).delete();
      });
    });

  const notifRef = firestore.collection('notifications');
  notifRef.where('for_post', '==', postId).get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        notifRef.doc(doc.id).delete();
      });
    });
};

export const getAnnouncementById = (postId) => {
  return new Promise((resolve, reject) => {
    firestore.collection('announcements').doc(postId).get()
      .then(doc => {
        if (doc.exists) {
          const post = {...doc.data(), id: postId};
          convertAuthorReference([post]).then(authors => {
            post.author = authors[0].data();
            resolve(post);
          });
        } else {
          reject(null);
        }
      })
      .catch(reject);
  });
};

export const getUnreadAnnouncementForUser = async (userId) => {
  const announcements = await fetchAllAnnouncements();
  let unread = null;
  announcements.forEach(a => {
    if (!a.read_by.includes(userId) && !unread) {
      unread = a;
    }
  });
  return unread;
};

export const markAllAnnouncementsAsReadForUser = async (userId) => {
  const announcements = await fetchAllAnnouncements();
  announcements.forEach(a => {
    firestore.collection('announcements').doc(a.id).update({read_by: [...a.read_by, userId]})
  })
}