import { storage } from './firebase';
import {AsyncStorage} from 'react-native';

export const uploadMediaToFirebase = async (mediaUri, title, onError, onUpdate) => {
  // Why are we using XMLHttpRequest? See:
  // https://github.com/expo/expo/issues/2402#issuecomment-443726662
  const blob = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function() {
      resolve(xhr.response);
    };
    xhr.onerror = function(e) {
      reject(new TypeError('Network request failed'));
    };
    xhr.responseType = 'blob';
    xhr.open('GET', mediaUri, true);
    xhr.send(null);
  });

  const ref = 
  storage
    .ref()
    .child(title);
  const uploadTask = ref.put(blob);

  return new Promise((resolve, reject) => {
    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on('state_changed', onUpdate, onError, () => {
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      uploadTask.snapshot.ref.getDownloadURL().then((url) => {
        resolve(url);
      });
    });
  });

  // return await snapshot.ref.getDownloadURL();
};

export const saveUserDataToDevice = (data) => {
  AsyncStorage.setItem('userData', JSON.stringify(data));
};

export const getUserDataFromDevice = async () => {
  try {
    const data = await AsyncStorage.getItem('userData');
    return JSON.parse(data);
  } catch (e) {
    // console.log('No user data on device.');
  }
};