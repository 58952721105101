import React from 'react';
import { createContext, useState } from 'react';

const initialNetworkContext = {
  connected: true
};

export const NetworkContext = createContext(initialNetworkContext);

export default function NetworkContextProvider({children}) {
  const [connected, setConnected] = useState(initialNetworkContext.connected);

  return (
    <NetworkContext.Provider value={{connected, setConnected}}>
      {children}
    </NetworkContext.Provider>
  );
}