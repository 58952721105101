import React from 'react';
import {View} from 'react-native';
import { Text, DataTable, Colors } from 'react-native-paper';
import { fullWidthPadding } from '../constants/Misc';
import { getTeamColor } from '../../utilities/getColorForTeam';
import {useNavigation} from '@react-navigation/native'

export default function TopTen({topTen}) {

  const navigation = useNavigation()

  const navToProfile = (user) => {
    navigation.navigate('Profile', {user})
  }

  return (
    <DataTable style={{ marginBottom: 0, width: fullWidthPadding }}>
      <DataTable.Header>
        <DataTable.Title>Rank</DataTable.Title>
        <DataTable.Title style={{ marginLeft: -55 }}>Player</DataTable.Title>
        <DataTable.Title numeric style={{ marginRight: 30 }}>
     Score
        </DataTable.Title>
      </DataTable.Header>
      {topTen.map((result, i) => (
        <DataTable.Row key={i} onPress={() => navToProfile(result.player)}>
          <View
            style={{
              width: 35,
              height: 35,
              backgroundColor: getTeamColor(result.player.team),
              borderRadius: 50,
              justifyContent: 'center',
              alignItems: 'center',
              marginVertical: 6,
            }}
          >
            <Text
              style={{ color: Colors.black, fontSize: 24, fontWeight: '700' }}
            >
              {i + 1}
            </Text>
          </View>
          <DataTable.Cell style={{ marginLeft: 40 }}>
            <Text style={{ fontSize: 16 }}>{result.player.full_name}</Text>
          </DataTable.Cell>
          <View
            style={{
              width: 80,
              height: 35,
              backgroundColor: Colors.black,
              borderRadius: 50,
              justifyContent: 'center',
              alignItems: 'center',
              marginVertical: 6,
            }}
          >
            <Text
              style={{ color: Colors.white, fontSize: 16, fontWeight: '500' }}
            >
              {result.score}
            </Text>
          </View>
        </DataTable.Row>
      ))}
    </DataTable>
  );
}