/* eslint-disable react/display-name */
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import * as React from 'react';

import TabBarIcon from '../components/TabBarIcon';
import FeedStack from './FeedStack';
import ChallengesStack from './ChallengesStack';
import MiscStack from './MiscStack';
import NotificationsStack from './NotificationStack';

const BottomTab = createBottomTabNavigator();
const INITIAL_ROUTE_NAME = 'Home';

export default function BottomTabNavigator({ navigation }) {
  // Set the header title on the parent stack navigator depending on the
  // currently active tab. Learn more in the documentation:
  // https://reactnavigation.org/docs/en/screen-options-resolution.html
  navigation.setOptions({ header: () => null });

  return (
    <BottomTab.Navigator initialRouteName={INITIAL_ROUTE_NAME} tabBarOptions={{keyboardHidesTabBar: true}}>
      <BottomTab.Screen
        name="Feed"
        component={FeedStack}
        options={{
          title: 'Feed',
          tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="ios-albums" />,
        }}
      />
      <BottomTab.Screen
        name="Challenges"
        component={ChallengesStack}
        options={{
          title: 'Challenges',
          tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="ios-flag" />,
        }}
      />
      <BottomTab.Screen
        name="Notifications"
        component={NotificationsStack}
        options={{
          title: 'Notifications',
          tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="ios-notifications" />,
        }}
      />
      <BottomTab.Screen
        name="Miscellaneous Info"
        component={MiscStack}
        options={{
          title: 'Info',
          tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="ios-menu" />,
        }}
      />
    </BottomTab.Navigator>
  );
}