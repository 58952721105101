import {
  Button, Portal,
} from "react-native-paper";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ScrollView, View, Clipboard } from "react-native";
import {
  deleteComment,
  fetchCommentsForPostId,
  submitCommentForPost,
} from "../../api/comments";

import { AuthContext } from "../../context/AuthContext";
import CommentInput from "../../components/CommentInput";
import FeedCard from "../../components/feed/FeedCard";
import RefreshButton from "../../components/RefreshButton";
import { getPostById } from "../../api/posts";
import { getAnnouncementById } from "../../api/announcements";
import { UpdateContext } from "../../context/UpdateContext";
import LikesDialog from "../../components/postCard/LikesDialog";
import CommentCard from "../../components/CommentCard";

export default function PostDetailScreen({ navigation, route }) {
  const [post, setPost] = useState(route.params.post);
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showLikesDialog, setShowLikesDialog] = useState(false)
  const [likesForComment, setLikesForComment] = useState([])
  const [showAllComments, setShowAllComments] = useState(false)

  const scrollViewRef = useRef(null);
  const { authContext } = useContext(AuthContext);
  const { user } = authContext;

  const updateScreen = () => {
    updateComments();
    updatePost();
  };

  const updateComments = () => {
    setIsLoading(true);
    fetchCommentsForPostId(post?.id).then((c) => {
      setComments(c);
      setIsLoading(false);
    });
  };

  const updatePost = () => {
    setIsLoading(true);
    if ((post?.id).includes('announcement')) {
      getAnnouncementById(post?.id).then(a => {
        setPost(a);
        setIsLoading(false);
      })
    } else {
      getPostById(post?.id).then((p) => {
        setPost(p);
        setIsLoading(false);
      }).catch(console.warn)
    }
  };

  const submitComment = (commentValue) => {
    const commentNumber = comments.length + 1;
    submitCommentForPost(user?.id, commentValue, post?.id, commentNumber);
    updateScreen();
    scrollViewRef.current.scrollToEnd();
  };

  const removeComment = (c) => {
    deleteComment(c)
      .then(updateScreen)
      .catch((e) => {
        Clipboard.setString(e)
        alert('There was a problem deleting the comment. Error copied to clipboard. (You can share this with your captain)')
        updateScreen()
      });
  };

  useEffect(() => {
    if (!post && route.params.postId) {
      setIsLoading(true)
      if (route.params.postId.includes('announcement')) {
        getAnnouncementById(route.params.postId).then(a => {
          setPost(a);
          setIsLoading(false);
        }).catch(e => {
          console.warn(e)
          navigation.goBack()
        })
      } else {
        getPostById(route.params.postId).then((p) => {
          setPost(p);
          setIsLoading(false);
        }).catch(e => {
          console.warn(e)
          navigation.goBack()
        })
      }
    } else {
      updateScreen()
    }
  }, []);

  useEffect(() => {
    if (post) {
      updateComments()
    }
  }, [post])

  React.useLayoutEffect(() => {
    navigation.setOptions({
      // eslint-disable-next-line react/display-name
      headerRight: () => (
        <RefreshButton onPress={updateScreen} isLoading={isLoading} />
      ),
    });
  }, [navigation, isLoading]);

  if (!post) return null;

  const slicedComments = comments.length > 10 && !showAllComments ? comments.slice(-5) : comments
  return (
      <UpdateContext.Provider value={{updateScreen}}>
        <ScrollView
          ref={scrollViewRef}
          contentContainerStyle={{ alignItems: "center", paddingTop: 6 }}
        >
          <FeedCard item={post} />
          {(comments.length > 10 && !showAllComments) && <Button style={{marginBottom: 5}} onPress={() => setShowAllComments(true)}>See All Comments</Button>}
          {(comments.length > 10 && showAllComments) && <Button style={{marginBottom: 5}} onPress={() => setShowAllComments(false)}>Collapse Comments</Button>}
          {slicedComments.map((comment) => (
            <CommentCard
              key={comment.id}
              comment={comment}
              removeComment={removeComment}
              updateComments={updateComments}
              setLikesForComment={setLikesForComment}
              setShowLikesDialog={setShowLikesDialog}
            />
          ))}
          <View style={{ height: 300 }} />
        </ScrollView>
        <CommentInput submitComment={submitComment} post={post} />
        {showLikesDialog && (
          <LikesDialog visible={showLikesDialog} setVisible={setShowLikesDialog} liked_by={likesForComment} />
        )}
      </UpdateContext.Provider>
  );
}