import { firestore } from './firebase';
import { getUserById, fetchAllUsers } from './users';

export const fetchNotificationsForUser = (userId) => {
  const notifs = [];
  return new Promise((resolve, reject) => {
    firestore.collection('notifications').where('for_user', '==', userId).get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          notifs.push(doc.data());
        });
        resolve(notifs.sort((a, b) => b.created_at - a.created_at));
      })
      .catch(e => {
        console.warn('Could not get notifs: ', e);
        reject([]);
      });
  });
};

export const addNotification = async (forUser, forPost, message, eventId = '', title = null) =>  {
  const notifData = {
    for_user: forUser,
    for_post: forPost,
    event: message,
    event_id: eventId,
    created_at: (new Date()).getTime()
  };
  firestore.collection('notifications').add(notifData);

  const user = await getUserById(forUser);
  sendNotificationToExpo(user.push_token, forPost, message, title);
};

export const sendNotificationToExpo = async (token, postId, msg, title = null) => {
  const message = {
    to: token,
    sound: 'default',
    body: msg,
    data: {postId},
    _displayInForeground: true,
  };
  if (title) {
    message.title = title;
  }
  fetch('https://exp.host/--/api/v2/push/send', {
    mode: 'no-cors',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Accept-encoding': 'gzip, deflate',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(message),
  });
};

export const notifyEveryone = async (postId, msg, title, callback = () => {}) => {
  fetchAllUsers().then(users => {
    users.forEach(user => {
      addNotification(user.id, postId, msg, '', title);
    });
    callback();
  });
};