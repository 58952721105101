import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import ChallengesScreen from '../screens/challenges/ChallengesScreen';
import SubmitPostScreen from '../screens/SubmitPostScreen';
import ViewChallengePostsScreen from '../screens/ViewChallengePostsScreen';
import ProfileScreen from '../screens/ProfileScreen';
import PostsForUserScreen from '../screens/PostsForUserScreen';
import PostDetailScreen from '../screens/feed/PostDetailScreen';

const Stack = createStackNavigator();

export default function ChallengesStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Challenges"
        component={ChallengesScreen}
        options={{title: 'Day 1 Challenges', headerBackTitleVisible: false}}
      />
      <Stack.Screen
        name='SubmitPost'
        component={SubmitPostScreen}
        options={({route}) => ({title: `Submit #${route.params.challenge.title}`})}
      />
      <Stack.Screen
        name='ViewChallengePostsScreen'
        component={ViewChallengePostsScreen}
        options={({route}) => ({title: `#${route.params.challenge}`, headerBackTitleVisible: false})}
      />
      <Stack.Screen
          name="Profile"
          component={ProfileScreen}
          options={({route}) => ({
            title: route?.params?.user?.full_name || 'Profile'
          })} />
      <Stack.Screen
        name="PostsForUser"
        component={PostsForUserScreen}
        options={({route}) => ({
          title: `Posts by ${route?.params?.user?.full_name || 'User'}`
        })}
      />
      <Stack.Screen
        name="PostDetail"
        component={PostDetailScreen}
        options={{
          title: 'Post Detail'
        }}
      />
    </Stack.Navigator>
  );
}