import * as WebBrowser from "expo-web-browser";

import { AnselAward, WhateverAward, WowAward, BearUpAward, BearDownAward } from "../Awards";
import {
  Card,
  Colors,
  Paragraph,
  Text,
} from "react-native-paper";
import React, { useContext, useEffect, useState, useRef } from "react";
import { StyleSheet, TouchableWithoutFeedback, View, Clipboard } from "react-native";
import {
  addAwardToPost,
  removeAwardFromPost,
  scorePost,
  removeContenderFromPost,
  addContenderToPost,
} from "../../api/scoring";
import {
  deleteAnnouncement,
  updateLikesForAnnouncement,
} from "../../api/announcements";
import { deletePost, updateLikesForPost } from "../../api/posts";
import { fullWidthPadding, maxColumnWidth } from "../../constants/Misc";

import { AuthContext } from "../../context/AuthContext";
import HuntAlbum from "../HuntAlbum";
import HuntImage from "../HuntImage";
import HuntVideo from "../HuntVideo";
import { addNotification } from "../../api/notifications";
import { getChallengeById } from "../../api/challenges";
import { getTeamColor } from "../../../utilities/getColorForTeam";
import moment from "moment";
import { useNavigation, useRoute } from "@react-navigation/native";
import { UpdateContext } from "../../context/UpdateContext";
import LikesDialog from "../postCard/LikesDialog";
import ScoreDialog from "../postCard/ScoreDialog";
import { LikeIconButton, CommentIconButton, GeneralIconButton } from "../IconButtons";
import ButtonWrapper from "../ButtonWrapper";
import PostMenu from "../postCard/PostMenu";
import FeedCardTitle from "../postCard/FeedCardTitle";
import ParsedText from "react-native-parsed-text";
import AwardDialog from "../postCard/AwardDialog";
import ContenderDialog from "../postCard/ContenderDialog";

export default function FeedCard({
  item,
  navToDetail = () => {},
}) {
  const {
    author,
    content = "I love pie",
    challenge = "antseye",
    comment_count = "0",
    liked_by = [],
    attachments = null,
    created_at = 0,
    scored = false,
    score = 0,
    awards = [],
  } = item;

  // Temp Values
  const [tempLikes, setTempLikes] = useState({liked_by: []})
  const [isDeleting, setIsDeleting] = useState(false)

  const [postMenuVisible, setPostMenuVisible] = useState(false);
  const [activeTab, setActiveTab] = useState(
    attachments ? attachments[0]?.type : null
  );
  const [scoreDialogVisible, setScoreDialogVisible] = useState(false);
  const [challengeObject, setChallengeObject] = useState(null);
  const [showLikesDialog, setShowLikesDialog] = useState(false);
  const [showAwardDialog, setShowAwardDialog] = useState(false);
  const [showContenderDialog, setShowContenderDialog] = useState(false);

  const navigation = useNavigation();
  const route = useRoute()
  const {updateScreen} = useContext(UpdateContext)
  const auth = useContext(AuthContext);
  const { user } = auth.authContext;

  const handleLike = () => {
    updateScreen();
    let newLikes = {};
    if (isLiked) {
      newLikes = { liked_by: liked_by.filter((id) => id !== user.id) };
    } else {
      newLikes = { liked_by: [...liked_by, user.id] };
      if (user.id !== author.id) {
        const msg = `${user.first_name} liked your post for #${challenge}`;
        addNotification(author.id, item.id, msg);
      }
    }
    setTempLikes(newLikes)
    if (challenge === "announcement") {
      updateLikesForAnnouncement(item.id, newLikes);
    } else {
      updateLikesForPost(item.id, newLikes);
    }
  };

  const removePost = () => {
    setIsDeleting(true)
    if (challenge === "announcement") {
      deleteAnnouncement(item.id)
        .then(() => {
          updateScreen()
          setPostMenuVisible(false)
          if (route.name !== 'AnnouncementList') {
            navigation.goBack();
          }
        })
        .catch(console.warn);
    } else {
      deletePost(item)
        .then(() => {
          updateScreen()
          setPostMenuVisible(false)
          if (route.name !== 'Feed') {
            navigation.goBack();
          }
        })
        .catch(console.warn);
    }
  };

  const handleHyperlink = (url) => {
    let link = url
    if (!url.includes('http')) link = `http://${url.trim().toLowerCase()}`
    WebBrowser.openBrowserAsync(link).catch(e => alert(`Couldnt open link: ${link} \n\nError: ${e}`))
  };

  const showScoreDialog = () => {
    setScoreDialogVisible(true);
  };

  const handleCopy = () => {
    Clipboard.setString(content)
    setPostMenuVisible(false)
  }

  const handleScorePost = (score) => {
    setScoreDialogVisible(false);
    scorePost(score, item.id)
      .then(updateScreen)
      .catch(console.warn);
  };

  const toggleAward = (award) => {
    updateScreen()
    if (awards.includes(award)) {
      removeAwardFromPost(award, item)
        .then(() => updateScreen())
        .catch(console.warn);
    } else {
      addAwardToPost(award, item)
        .then(() => updateScreen())
        .catch(console.warn);
    }
    setShowAwardDialog(false)
  };

  const navigateToPostDetail = () => {
    if (route.name !== 'PostDetail') {
      navigation.navigate('PostDetail', {post: item});
    }
  };

  const toggleContenderFor = (contender) => {
    if (item.contender_for) {
      updateScreen()
      if (item.contender_for.includes(contender)) {
        removeContenderFromPost(contender, item)
          .then(() => updateScreen())
          .catch(console.warn);
      } else {
        addContenderToPost(contender, item)
          .then(() => updateScreen())
          .catch(console.warn);
      }
    } else {
      addContenderToPost(contender, item)
      .then(() => updateScreen())
      .catch(console.warn);
    }
    setShowContenderDialog(false)
  }

  const renderPostContent = () => {
    if (!attachments) return null;
    const media = attachments.find((m) => m.type === activeTab);
    if (!media && attachments.length > 0) {
      setActiveTab(attachments[0]?.type)
      return null;
    } else if (!media) return null;
    switch (activeTab) {
      case "photo":
        return <HuntImage source={{ uri: media.url }} onPress={navigateToPostDetail} />;
      case "album":
        return <HuntAlbum photos={media.photos} post={item} />;
      case "video":
        return <HuntVideo videoUrl={media.videoUrl} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!challenge?.includes('announcement')) {
      getChallengeById(challenge).then(setChallengeObject).catch(console.warn);
    }
  }, []);

  const isLiked = liked_by.includes(user?.id) || tempLikes?.liked_by?.includes(user?.id);

  return (!user || isDeleting) ? null : (
    <>
      <Card
        style={[
          styles.container,
          { borderWidth: 1, borderColor: getTeamColor(author?.team) },
        ]}
      >
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <FeedCardTitle player={author} challenge={challengeObject} />
          <PostMenu
            visible={postMenuVisible}
            setVisible={setPostMenuVisible}
            showAwardDialog={() => {
              setShowAwardDialog(true)
              setPostMenuVisible(false)
            }}
            showContenderDialog={() => {
              setShowContenderDialog(true)
              setPostMenuVisible(false)
            }}
            deletePost={removePost}
            author={author}
            copyText={handleCopy}
          />
        </View>
        <TouchableWithoutFeedback onPress={navToDetail} onLongPress={handleCopy}>
          <View>
            <Card.Content style={styles.content}>
                <Paragraph
                  style={styles.contentText}
                >
                   <ParsedText
                    parse={
                      [
                        {type: 'url', style: styles.linkStyle, onPress: handleHyperlink},
                        {pattern: /@[a-zA-Z^\S]+/, style: {color: Colors.blue500}},
                      ]
                    }
                  >
                    {content}
                  </ParsedText>
                </Paragraph>
              {user?.admin && item.contender_for?.length > 0 && (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 12,
                  }}
                >
                  {item.contender_for?.includes('ansel') && (
                    <View style={{opacity: 0.2}}>
                      <AnselAward />
                    </View>
                  )}
                  {item.contender_for?.includes('wow') && (
                    <View style={{opacity: 0.2}}>
                      <WowAward />
                    </View>
                  )}
                  {item.contender_for?.includes('whatever') && (
                    <View style={{opacity: 0.2}}>
                      <WhateverAward />
                    </View>
                  )}
                </View>
              )}
              {awards.length > 0 && (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 12,
                  }}
                >
                  {awards.includes("ansel") && <AnselAward />}
                  {awards.includes("wow") && <WowAward />}
                  {awards.includes("whatever") && <WhateverAward />}
                  {awards.includes("bearup") && <BearUpAward />}
                  {awards.includes("beardown") && <BearDownAward />}
                </View>
              )}
            </Card.Content>
          </View>
        </TouchableWithoutFeedback>

        {attachments && attachments.length > 1 && (
          <View
            style={styles.attachmentWrapper}
          >
            {attachments.map((tab, i) => (
              <ButtonWrapper
                key={i}
                mode={activeTab === tab.type ? "contained" : "text"}
                onPress={() => setActiveTab(tab.type)}
              >
                {tab.type}
              </ButtonWrapper>
            ))}
          </View>
        )}
        {renderPostContent()}
        <Card.Actions style={{ justifyContent: "space-between", height: 50 }}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <View style={{ flexDirection: "row", alignItems: "center", marginRight: 6 }}>
              <LikeIconButton
                liked={isLiked}
                onPress={handleLike}
                onLongPress={() => setShowLikesDialog(true)}
              />
              <Text
                style={styles.likesText}
              >
                {liked_by.length < tempLikes.liked_by.length ? tempLikes.liked_by.length : liked_by.length}
              </Text>
            </View>

            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <CommentIconButton onPress={navToDetail} />
              <Text
                style={styles.commentCountText}
              >
                {comment_count}
              </Text>
            </View>

            {challenge !== "announcement" && user.admin && (
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <GeneralIconButton
                  style={{marginLeft: 24}}
                  icon={`thumb-up${scored ? "" : "-outline"}`}
                  color={Colors.green500}
                  size={25}
                  onPress={showScoreDialog}
                />
                <Text
                  style={styles.scoreText}
                >
                  {score}
                </Text>
              </View>
            )}
          </View>

          <Text style={styles.timeText}>{moment(created_at).fromNow()}</Text>
        </Card.Actions>
      </Card>
      {scoreDialogVisible && (
        <ScoreDialog
          visible={scoreDialogVisible}
          setVisible={setScoreDialogVisible}
          author={author}
          currentScore={item.score}
          challenge={challenge}
          challengeObject={challengeObject}
          handleScorePost={handleScorePost}
        />
      )}
      {showLikesDialog && (
        <LikesDialog visible={showLikesDialog} setVisible={setShowLikesDialog} liked_by={liked_by} />
      )}
      {showAwardDialog && (
        <AwardDialog awards={awards} toggleAward={toggleAward} visible={showAwardDialog} setVisible={setShowAwardDialog} />
      )}
      {showContenderDialog && (
        <ContenderDialog contenderFor={item.contender_for} toggleContenderFor={toggleContenderFor} visible={showContenderDialog} setVisible={setShowContenderDialog} />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    width: fullWidthPadding,
    maxWidth: maxColumnWidth,
    marginHorizontal: 12,
    marginVertical: 6,
  },
  content: {
    paddingVertical: 8,
    marginTop: -8,
  },
  contentText: {
    fontSize: 16,
  },
  timeText: {
    fontSize: 12,
    opacity: 0.4,
    marginRight: 12,
  },
  linkStyle: {
    color: Colors.purple200,
    textDecorationLine: "underline",
  },
  attachmentWrapper: {
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
      paddingVertical: 8,
  },
  scoreText: {
    color: Colors.green500,
    fontSize: 18,
    fontWeight: "700",
    marginLeft: 8,
  },
  commentCountText: {
      color: Colors.blue500,
      fontSize: 18,
      fontWeight: "700",
      marginLeft: -6,
  },
  likesText: {
    color: Colors.red700,
    fontSize: 18,
    fontWeight: "700",
    marginLeft: -6,
  }
});
