export const getIconForService = service => {
  switch(service?.toLowerCase()) {
    case 'snapchat':
      return 'snapchat';
    case 'twitter':
      return 'twitter';
    case 'instagram':
      return 'instagram';
    default:
      return 'account-box';
  }
}