import React, { useState, useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import { Button, Card, Paragraph, TextInput } from 'react-native-paper';
import { getUserForEntryCode } from '../api/users';
import { AuthContext } from '../context/AuthContext';
import { saveUserDataToDevice } from '../api/storage';
import { fullWidthPadding, maxColumnWidth } from '../constants/Misc';

export default function AuthScreen() {
  const [entryCode, setEntryCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const {setUser} = useContext(AuthContext);

  const submitEntryCode = () => {
    setIsLoading(true);
    getUserForEntryCode(entryCode).then(user => {
      if (user) {
        setUser(user);
        saveUserDataToDevice(user);
      }
      setIsLoading(false);
    }).catch();
  };

  return (
    <View style={styles.container}>
      <Card style={styles.card}>
        <Card.Title title='Sign In' subtitleStyle={{marginTop: -4, fontSize: 14}} />
        <Card.Content style={styles.content}>
          <Paragraph style={styles.contentText}>Hello! Welcome to #TheHunt. Please enter your super secret entry code.</Paragraph>
          <TextInput
            label='Entry Code'
            value={entryCode}
            onChangeText={setEntryCode}
            mode='outlined'
            autoCapitalize='none'
            autoCorrect={false}
            onSubmitEditing={submitEntryCode}
            disabled={isLoading}
          />        
        </Card.Content>
        <Card.Actions style={{justifyContent: 'flex-end'}}>
          <Button
            onPress={submitEntryCode}
            mode='contained'
            loading={isLoading}
            disabled={isLoading}
          >
          Sign In
          </Button>
        </Card.Actions>
      </Card>
    </View>
  
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center'
  },
  card: {
    margin: 12,
    width: fullWidthPadding,
    maxWidth: maxColumnWidth,
  },
  content: {
    paddingVertical: 8
  },
  contentText: {
    fontSize: 16,
    marginBottom: 24
  }
});