import * as ImagePicker from "expo-image-picker";

import {
  Avatar,
  Button,
  Colors,
  DataTable,
  List,
  Text,
  Title,
} from "react-native-paper";
import { fullWidthPadding } from "../../constants/Misc";
import {
  FlatList,
  ScrollView,
  StyleSheet,
  TouchableHighlight,
  View,
  TouchableOpacity,
} from "react-native";
import React, { useContext, useEffect, useState } from "react";
import { saveUserDataToDevice, uploadMediaToFirebase } from "../../api/storage";

import { AuthContext } from "../../context/AuthContext";
import RefreshButton from "../../components/RefreshButton";
import { addAvatarToUser } from "../../api/users";
import { getAllTeams } from "../../api/teams";
import { getTeamColor } from "../../../utilities/getColorForTeam";
import { getTopTen, getGladiatorScoreForUser } from "../../api/scoring";
import TopTen from "../../components/TopTen";
import moment from 'moment';
import { AnselAward, WowAward, WhateverAward } from "../../components/Awards";

const defaultTeams = [
  { name: "Water", victory_points: 0, num_posts_submitted: 0 },
  { name: "Earth", victory_points: 0, num_posts_submitted: 0 },
  { name: "Fire", victory_points: 0, num_posts_submitted: 0 },
  { name: "Air", victory_points: 0, num_posts_submitted: 0 },
];

const defaultTopTen = {
  updated_at: (new Date()).valueOf(),
  data: [
    { player: { full_name: "--" }, score: 0 },
    { player: { full_name: "--" }, score: 0 },
    { player: { full_name: "--" }, score: 0 },
    { player: { full_name: "--" }, score: 0 },
    { player: { full_name: "--" }, score: 0 },
    { player: { full_name: "--" }, score: 0 },
    { player: { full_name: "--" }, score: 0 },
    { player: { full_name: "--" }, score: 0 },
    { player: { full_name: "--" }, score: 0 },
    { player: { full_name: "--" }, score: 0 },
  ]
};

export default function MiscScreen({ navigation }) {
  const [teams, setTeams] = useState(defaultTeams);
  const [isLoading, setIsLoading] = useState(false);
  const [avatarResultUri, setAvatarResultUri] = useState(null);
  const [isSettingAvatar, setIsSettingAvatar] = useState(false);
  const [topTen, setTopTen] = useState(defaultTopTen);
  const [showTopTen, setShowTopTen] = useState(false)
  const [yourGladiatorScore, setYourGladiatorScore] = useState(0)

  const { authContext, setUser } = useContext(AuthContext);
  const { user } = authContext;

  const navToPlayerList = () => {
    navigation.navigate("PlayerList");
  };

  const navToUserPosts = () => {
    navigation.navigate("MyPosts");
  };

  const navToCreateAnnouncement = () => {
    navigation.navigate("CreateAnnouncement");
  };

  const navToAnnouncementList = () => {
    navigation.navigate("AnnouncementList");
  };

  const navToScoreCalculator = () => {
    navigation.navigate("Scores", { teams, updateTeams: updateInfo });
  };

  const navToContenders = () => {
    navigation.navigate("AwardContenders");
  }

  const navToAwardList = (title, award) => {
    navigation.navigate('AwardList', {title, award})
  }

  const updateInfo = () => {
    setIsLoading(true);
    getAllTeams().then((t) => {
      setTeams(t);
      setIsLoading(false);
    });
    getTopTen().then(setTopTen);
    getGladiatorScoreForUser(user).then(setYourGladiatorScore)
  };

  const selectAvatar = async () => {
    let result = await ImagePicker.launchImageLibraryAsync();
    if (!result.cancelled) {
      setAvatarResultUri(result.uri);
    }
  };

  const setAvatar = async () => {
    setIsSettingAvatar(true);
    const avatarUrl = await uploadAvatar();
    if (avatarUrl) {
      addAvatarToUser(user?.id, avatarUrl).then(() => {
        setAvatarResultUri(null);
        setIsSettingAvatar(false);
        setUser({ ...user, avatar: avatarUrl });
      });
    } else {
      alert("Could not upload photo :(");
    }
  };

  const uploadAvatar = async () => {
    return await uploadMediaToFirebase(avatarResultUri, `${user?.id}-avatar`);
  };

  const renderTeamData = ({ item }) => {
    return (
      <TouchableHighlight onPress={() => navigation.navigate('TeamProfile', {team: item})}>
        <View
          key={item.name}
          style={[styles.teamData, { backgroundColor: getTeamColor(item.name) }]}
        >
          <Text style={styles.teamText}>{`${item.name
            .charAt(0)
            .toUpperCase()}${item.name.slice(1)}`}</Text>
          <Text style={styles.scoreText}>{item.victory_points} VP</Text>
          {user?.admin && (
            <Text style={{fontSize: 12, opacity: .5}}>{item.num_posts_submitted} Posts</Text>
          )}
        </View>
      </TouchableHighlight>
    );
  };

  const logOut = () => {
    setUser(null);
    saveUserDataToDevice(null);
  };

  useEffect(() => {
    updateInfo();

    const unsubscribe = navigation.addListener("focus", updateInfo);
    return unsubscribe;
  }, []);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      // eslint-disable-next-line react/display-name
      headerRight: () => (
        <RefreshButton onPress={updateInfo} isLoading={isLoading} />
      ),
    });
  }, [navigation, isLoading]);

  const listItems = [
    {
      title: "Announcements",
      icon: "alert-decagram",
      action: navToAnnouncementList,
    },
    { title: "View My Posts", icon: "image-multiple", action: navToUserPosts },
    { title: "View All Players", icon: "account", action: navToPlayerList },
  ];

  if (user?.admin) {
    const newAnnouncement = {
      title: "Create Announcement",
      icon: "plus",
      action: navToCreateAnnouncement,
    };
    const calculate = {
      title: "Calculate Scores",
      icon: "trophy",
      action: navToScoreCalculator,
    };
    const seeContenders = {
      title: "See Contenders",
      icon: "star",
      action: navToContenders,
    };
    listItems.splice(0, 0, newAnnouncement);
    listItems.splice(1, 0, seeContenders)
    listItems.splice(2, 0, calculate);
  }

  return (
    <ScrollView contentContainerStyle={{ alignItems: "center" }}>
      <View
        style={{ flexDirection: "row", alignItems: "center", marginTop: 24 }}
      >
        <TouchableHighlight onPress={selectAvatar}>
          {user?.avatar && user?.avatar.length > 0 && !avatarResultUri ? (
            <View
              style={{
                backgroundColor: getTeamColor(user?.team),
                borderRadius: 50,
                width: 90,
                height: 90,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar.Image size={80} source={{ uri: user?.avatar }} />
            </View>
          ) : (
            <View>
              {avatarResultUri ? (
                <View
                  style={{
                    backgroundColor: getTeamColor(user?.team),
                    borderRadius: 50,
                    width: 90,
                    height: 90,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Avatar.Image size={80} source={{ uri: avatarResultUri }} />
                </View>
              ) : (
                <Avatar.Icon
                  icon="account"
                  size={90}
                  style={{
                    borderWidth: 5,
                    borderColor: getTeamColor(user?.team),
                  }}
                />
              )}
            </View>
          )}
        </TouchableHighlight>
        <View
          style={{
            marginLeft: 24,
            backgroundColor: getTeamColor(user?.team),
            padding: 12,
            borderRadius: 8,
          }}
        >
          <Text style={{ fontSize: 28, fontWeight: "700", maxWidth: 250 }}>
            {user?.full_name}
          </Text>
          <Text
            style={{ textTransform: "uppercase", opacity: 0.6, fontSize: 16 }}
          >
            TEAM {user?.team}
          </Text>
        </View>
      </View>
      {avatarResultUri && (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Button
            disabled={isSettingAvatar}
            style={{ marginTop: 12, marginRight: 12 }}
            mode="outlined"
            onPress={() => setAvatarResultUri(null)}
          >
            Cancel Edit
          </Button>
          <Button
            disabled={isSettingAvatar}
            style={{ marginTop: 12 }}
            mode="contained"
            onPress={setAvatar}
            loading={isSettingAvatar}
          >
            Save New Avatar
          </Button>
        </View>
      )}

      <View style={styles.gladiatorWrapper}>
        <View>
      <Text style={styles.gladiatorTitle}>Your Gladiator Score{'\n'}Updated {moment(topTen.updated_at).fromNow()}</Text>
          <Text style={styles.gladiatorScore}>
            {yourGladiatorScore}
          </Text>
        </View>
      </View>

      <FlatList
        scrollEnabled={false}
        contentContainerStyle={{ alignItems: "center" }}
        style={styles.teamList}
        data={teams}
        renderItem={renderTeamData}
        numColumns={2}
        keyExtractor={(item) => item.name}
        ListFooterComponent={() => {
          let num = 0
          teams.forEach(t => num += t.num_posts_submitted)
          return (
            <View style={[styles.gladiatorWrapper, {height: 60, marginTop: 6, backgroundColor: Colors.black, borderWidth: 1, borderColor: Colors.white}]}>
              <View>
                <Text style={[styles.gladiatorTitle, {color: Colors.white, opacity: .7}]}>Posts from All Players</Text>
                <Text style={[styles.gladiatorScore, {fontSize: 24, color: Colors.white, opacity: .8}]}>
                  {num} Posts
                </Text>
              </View>
            </View>
          )
        }}
      />

      <View style={{ width: fullWidthPadding, paddingTop: 12, borderRadius: 8, marginBottom: 48, backgroundColor: Colors.black, borderWidth: 1, borderColor: Colors.amber500}}>
        <Text style={[styles.gladiatorScore, {fontSize: 24, color: Colors.white}]}>
          Hall of Fame
        </Text>
        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginVertical: 12}}>
          <TouchableOpacity onPress={() => navToAwardList('#Ansel', 'ansel')}>
            <AnselAward />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => navToAwardList('#Wow', 'wow')}>
            <WowAward />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => navToAwardList('#Whatever', 'whatever')}>
            <WhateverAward />
          </TouchableOpacity>
        </View>

      </View>

      <View style={{ width: fullWidthPadding, paddingTop: 12, borderRadius: 8, marginBottom: 48, backgroundColor: Colors.black, borderWidth: 1, borderColor: Colors.amber500}}>
        <View>
          <Text style={[styles.gladiatorScore, {fontSize: 24, color: Colors.white}]}>
            Top 10 Gladiators
          </Text>
          <Text style={[styles.gladiatorTitle, {color: Colors.white}]}>Updated {moment(topTen.updated_at).fromNow()}</Text>
        </View>
        {showTopTen && <TopTen topTen={topTen.data} />}
        <Button mode='outlined' labelStyle={{color: Colors.amber500}} style={{marginTop: 24, marginBottom: 24, alignSelf: 'center'}} onPress={() => setShowTopTen(!showTopTen)}>{showTopTen ? 'Hide' : 'Show'} Top Ten</Button>
      </View>

      <FlatList
        scrollEnabled={false}
        style={{ width: fullWidthPadding }}
        data={listItems}
        renderItem={({ item, index }) => (
          <List.Item
            key={index}
            title={item.title}
            left={(props) => <List.Icon {...props} icon={item.icon} />}
            onPress={item.action}
          />
        )}
        keyExtractor={item => item.title}
      />
      <Button style={styles.logOutButton} onPress={logOut} mode="contained">
        Log Out
      </Button>
      <View style={{ height: 150 }} />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  logOutButton: {
    marginHorizontal: 12,
    marginTop: 48,
  },
  teamList: {
    width: fullWidthPadding,
    marginBottom: 48,
  },
  teamData: {
    width: fullWidthPadding / 2 - 6,
    justifyContent: "center",
    alignItems: "center",
    height: 120,
    margin: 4,
    borderRadius: 8,
  },
  teamText: {
    fontSize: 22,
    fontWeight: "700",
    opacity: 0.8,
  },
  scoreText: {
    fontSize: 38,
    fontWeight: "900",
  },
  challengeText: {
    fontSize: 12,
    marginTop: 12,
    opacity: 0.7,
  },
  gladiatorWrapper: {
    margin: 12,
    backgroundColor: Colors.grey900,
    borderRadius: 8,
    width: fullWidthPadding,
    height: 90,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  gladiatorTitle: {
    opacity: 0.4,
    textAlign: "center",
  },
  gladiatorScore: {
    fontSize: 36,
    fontWeight: "700",
    textAlign: "center",
  },
});
