import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import NotificationsScreen from '../screens/notifications/NotificationsScreen';
import PostDetailScreen from '../screens/feed/PostDetailScreen';
import PostsForUserScreen from '../screens/PostsForUserScreen';

const Stack = createStackNavigator();

export default function NotificationsStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Notifications"
        component={NotificationsScreen}
        options={{title: 'Notifications'}}
      />
      <Stack.Screen
        name="NotificationDetail"
        component={PostDetailScreen}
        options={({route}) => ({title: `${route.params.post?.challenge?.includes('announcement') ? 'Announcement' : 'Post'} Detail`})}
      />
      <Stack.Screen
        name="PostsForUser"
        component={PostsForUserScreen}
        options={({route}) => ({
          title: `Posts by ${route?.params?.user?.full_name || 'User'}`
        })}
      />
    </Stack.Navigator>
  );
}