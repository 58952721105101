import { Platform, StyleSheet, View, Text } from 'react-native';

import HuntImage from './HuntImage';
import React from 'react';
import Swiper from 'react-native-web-swiper';
import { fullWidthPadding } from '../constants/Misc';
import { useNavigation, useRoute } from '@react-navigation/native';
import { PrevButton, NextButton } from './IconButtons';

export default function HuntAlbum({
  photos,
  disableLightbox = false,
  onIndexChanged = () => {},
  post = null
}) {
  const navigation = useNavigation();
  const route = useRoute()

  const handleOnPress = (index) => {
    if (disableLightbox) return;
    if (Platform.OS === 'web' || route.name !== 'PostDetail') {
      navigateToPostDetail()
    } else {
      navigation.navigate('AlbumViewer', { photos, curPhotoIndex: index });
    }
  };

  const navigateToPostDetail = () => {
    navigation.navigate('PostDetail', {post});
  };

  return (
    <View style={styles.wrapper}>
      <Swiper controlsEnabled={true} loop onIndexChanged={onIndexChanged} controlsProps={{PrevComponent: PrevButton, NextComponent: NextButton}}>
        {photos.map((photo, i) => (
          <HuntImage
            key={i}
            source={{ uri: photo }}
            onPress={() => handleOnPress(i)}
            disableLightbox={true}
            album={true}
          />
        ))}
      </Swiper>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    width: fullWidthPadding - 2,
    height: fullWidthPadding - 2,
  },
});
