import React, { useContext, useState, useEffect } from 'react';
import Feed from '../../components/feed/Feed';
import { AuthContext } from '../../context/AuthContext';
import { getPostsForUserId } from '../../api/posts';
import { View, StyleSheet } from 'react-native';
import {Text} from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import RefreshButton from '../../components/RefreshButton';

export default function MyPosts() {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigation = useNavigation();
  const {authContext} = useContext(AuthContext);
  const {user} = authContext;

  const fetchPosts = () => {
    setIsLoading(true);
    getPostsForUserId(user.id).then(posts => {
      setPosts(posts);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      // eslint-disable-next-line react/display-name
      headerRight: () => (
        <RefreshButton onPress={fetchPosts} isLoading={isLoading} />
      ),
    });
  }, [navigation, isLoading]);

  return posts.length === 0 ? (
    <View style={styles.emptyView}>
      <Text style={{opacity: .4, fontSize: 24, textAlign: 'center', width: 200}}>Hey, there are no posts here.{'\n\n'}Go submit some!</Text>
    </View>
  ) : (
    <Feed posts={posts} updateScreen={fetchPosts} />
  );
}

const styles = StyleSheet.create({
  emptyView: {
    height: 300,
    justifyContent: 'center',
    alignItems: 'center'
  }
});