import React, { Component, useState, useEffect } from 'react';
import { View, ScrollView, TouchableHighlight, StyleSheet, FlatList } from 'react-native';
import { Avatar, Text, List, Colors } from 'react-native-paper';
import { getTeamColor } from '../../utilities/getColorForTeam';
import { fullWidthPadding } from '../constants/Misc';
import { getGladiatorScoreForUser, getTopTen } from '../api/scoring';
import { getIconForService } from '../../utilities/getIconForService';
import * as WebBrowser from 'expo-web-browser'
import { getUserById } from '../api/users';
import moment from 'moment';

export default function ProfileScreen({navigation, route}) {
  const [user, setUser] = useState(route.params.user)
  const [gladiatorScore, setGladiatorScore] = useState(0)
  const [topTen, setTopTen] = useState({updated_at: moment().subtract(1, 'days')})

  const navToPostsForUser = () => {
    navigation.navigate('PostsForUser', {user})
  };

  const handleContact = (url) => {
    WebBrowser.openBrowserAsync(url).catch(console.warn)
  }

  useEffect(() => {
    if (!user) {
      getUserById(route.params.userId).then(user => {
        setUser(user)
        getGladiatorScoreForUser(user).then(setGladiatorScore)
      })
    } else {
      getGladiatorScoreForUser(user).then(setGladiatorScore)
    }
    getTopTen().then(setTopTen)
  }, [])

  return (
    <ScrollView contentContainerStyle={{alignItems: 'center'}}>
      <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 24}}>
          {user?.avatar && user?.avatar.length > 0 ? (
            <View style={{ backgroundColor: getTeamColor(user?.team), borderRadius: 50, width: 90, height: 90, justifyContent: 'center', alignItems: 'center'}}>
              <Avatar.Image size={80} source={{uri: user?.avatar}} />
            </View>
          ): (
            <View>
              <Avatar.Icon icon='account' size={90} style={{borderWidth: 5, borderColor: getTeamColor(user?.team)}} />
            </View>
          )}
        <View style={{marginLeft: 24, backgroundColor: getTeamColor(user?.team), padding: 12, borderRadius: 8}}>
          <Text style={{fontSize: 28, fontWeight: '700', maxWidth: 250}}>
            {user?.full_name}
          </Text>
          <Text style={{textTransform: 'uppercase', opacity: .6, fontSize: 16}}>TEAM {user?.team}</Text>
        </View>
      </View>

      <View style={styles.gladiatorWrapper}>
        <View>
          <Text style={styles.gladiatorTitle}>
          Gladiator Score{'\n'}Updated {moment(topTen.updated_at).fromNow()}
          </Text>
          <Text style={styles.gladiatorScore}>
            {gladiatorScore || 0}
          </Text>
        </View>
      </View>

      <FlatList
        scrollEnabled={false}
        style={{width: fullWidthPadding}}
        data={[
          {title: `View Posts by ${user?.first_name}`, icon: 'image-multiple', action: navToPostsForUser}
        ]}
        renderItem={({item, index}) => (
          <List.Item
            key={index}
            title={item.title}
            left={props => <List.Icon {...props} icon={item.icon} />}
            onPress={item.action}
          />
        )} />

        <View style={{width: fullWidthPadding}}>
        {user?.contact_info && (
          user?.contact_info.map((c, i) => (
            <List.Item
              key={i}
              title={c.title}
              left={props => <List.Icon {...props} icon={getIconForService(c.title)} />}
              onPress={() => handleContact(c.link)}
            />
          ))
        )}
        </View>

    </ScrollView>
  )
}

const styles = StyleSheet.create({
  gladiatorWrapper: {
    margin: 12,
    backgroundColor: Colors.grey900,
    borderRadius: 8,
    width: fullWidthPadding,
    height: 90,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  gladiatorTitle: {
    opacity: .4,
    textAlign: 'center'
  },
  gladiatorScore: {
    fontSize: 36,
    fontWeight: '700',
    textAlign: 'center'
  }
})