import React, { useState, useEffect } from 'react';
import { View, Modal, Image } from 'react-native';
import ImageZoom from 'react-native-image-pan-zoom';
import { SCREEN_WIDTH, SCREEN_HEIGHT } from '../constants/Layout';
import CachedImage from '../components/CachedImage';
import { DEFAULT_IMG } from '../constants/Misc';
import { IconButton, Colors } from 'react-native-paper';
import * as MediaLibrary from 'expo-media-library';
import * as FileSystem from 'expo-file-system';

const imageWidth = SCREEN_WIDTH - 24 > 500 ? 500 : SCREEN_WIDTH - 24;

export default function ImageViewModal({navigation, route}) {
  const [modalVisible, setModalVisible] = useState(true);

  const imgSource = route.params.imgSource;

  const handleDownload = async () => {
    FileSystem.downloadAsync(imgSource.uri, `${FileSystem.cacheDirectory}temp.png`).then(({uri}) => {
      MediaLibrary.saveToLibraryAsync(uri).then(() => {
        alert('Photo saved!');
      }).catch(e => {
        console.log('Photo could not be saved: ', e);
        alert('Photo could not be saved.');
      });
    })
  };

  const handleSwipeDown = () => {
    setModalVisible(false);
    navigation.goBack();
  };

  return (
    <Modal transparent={false} visible={modalVisible}>
      <View style={{backgroundColor: '#000', flex: 1, position: 'relative'}}>
        <ImageZoom
          cropWidth={SCREEN_WIDTH}
          cropHeight={SCREEN_HEIGHT}
          imageWidth={imageWidth}
          imageHeight={imageWidth}
          enableSwipeDown
          onSwipeDown={handleSwipeDown}
        >
          <CachedImage
            source={imgSource}
            style={{width: imageWidth, height: imageWidth}}
            resizeMode='contain'
            defaultSource={DEFAULT_IMG}
          /> 
        </ImageZoom>
        <View style={{position: 'absolute', top: 30, left: 6}}>
          <IconButton icon='download' size={38} color={Colors.white} onPress={handleDownload} />
        </View>
        <View style={{position: 'absolute', top: 30, right: 6}}>
          <IconButton icon='close' size={38} color={Colors.white} onPress={handleSwipeDown} />
        </View>
      </View>
    </Modal>
  );
}
