import React from 'react';
import { View } from 'react-native';
import ImageZoom from 'react-native-image-pan-zoom';
import { SCREEN_WIDTH, SCREEN_HEIGHT } from '../constants/Layout';
import CachedImage from '../components/CachedImage';
import { DEFAULT_IMG } from '../constants/Misc';
import Swiper from 'react-native-web-swiper';
import { PrevButton, NextButton } from '../components/IconButtons';

const imageWidth = SCREEN_WIDTH - 24 > 500 ? 500 : SCREEN_WIDTH - 24;

export default function AlbumViewWeb({navigation, route}) {
  const photoList = route.params.photos;
  const photoIndex = route.params.curPhotoIndex;

  const handleSwipeDown = () => {
    navigation.goBack();
  };

  return (
    <View style={{backgroundColor: '#000', flex: 1}}>
      <Swiper from={photoIndex} controlsEnabled={true} controlsProps={{PrevComponent: PrevButton, NextComponent: NextButton}}>
        {photoList.map((photo, i) => (
          <ImageZoom
            key={i}
            cropWidth={SCREEN_WIDTH}
            cropHeight={SCREEN_HEIGHT - 80}
            imageWidth={imageWidth}
            imageHeight={imageWidth}
            enableSwipeDown
            onSwipeDown={handleSwipeDown}
          >
            <CachedImage
              source={{uri: photo}}
              style={{width: imageWidth, height: imageWidth}}
              resizeMode='contain'
              defaultSource={DEFAULT_IMG}
            /> 
          </ImageZoom>
        ))}
      </Swiper>
    </View>
  );
}
