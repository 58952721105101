import React, { Component, useState, useEffect, useContext } from 'react';
import { View, StyleSheet, ScrollView, TouchableHighlight } from 'react-native';
import { Card, Paragraph, Text, Colors } from 'react-native-paper';
import RefreshButton from '../../components/RefreshButton';
import { fetchNotificationsForUser } from '../../api/notifications';
import {AuthContext} from '../../context/AuthContext';
import { fullWidthPadding } from '../../constants/Misc';
import { getPostById } from '../../api/posts';
import { getAnnouncementById } from '../../api/announcements';
import moment from 'moment';
import { MaterialCommunityIcons } from '@expo/vector-icons';

export default function NotificationsScreen({navigation}) {
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const {authContext} = useContext(AuthContext);
  const {user} = authContext;

  const viewPost = (postId) => {
    navigation.navigate('NotificationDetail', {postId})
  };

  const updateNotifications = () => {
    setIsLoading(true);
    fetchNotificationsForUser(user.id)
      .then(notifs => {
        setIsLoading(false);
        setNotifications(notifs);
      });
  };

  const getEventIcon = (notif) => {
    if (notif.event.includes('liked')) {
      return <MaterialCommunityIcons name='heart' size={24} color={Colors.red500} />
    } else if (notif.event.includes('award')) {
      return <MaterialCommunityIcons name='trophy' size={24} color={Colors.yellow600} />
    } else if (notif.event.includes('commented')) {
      return <MaterialCommunityIcons name='message' size={24} color={Colors.blue500} />
    } else if (notif.event.includes('announcement')) {
      return <MaterialCommunityIcons name='alert-decagram' size={24} color={Colors.green500} />
    } else {
      return <MaterialCommunityIcons name='bell' size={24} color={Colors.grey200} />
    }
  }

  useEffect(() => {
    updateNotifications();
    const unsubscribe = navigation.addListener('focus', updateNotifications);
    return unsubscribe;
  }, []);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      // eslint-disable-next-line react/display-name
      headerRight: () => (
        <RefreshButton onPress={updateNotifications} isLoading={isLoading} />
      ),
    });
  }, [navigation, isLoading]);

  return (
    <ScrollView contentContainerStyle={styles.container}>
      {notifications.length === 0 && (
        <View style={{width: fullWidthPadding, height: 200, justifyContent: 'center', alignItems: 'center'}}>
          <Text style={{fontSize: 24, opacity: .4}}>No notifications - yet!</Text>
        </View>
      )}
      {notifications.map((notif, i) => (
        <TouchableHighlight key={i} onPress={() => viewPost(notif.for_post)}>
          <View>
            <Card key={i} style={styles.card}>
              <Card.Content style={styles.content}>
                <View>
                  {getEventIcon(notif)}
                </View>
                <View style={{width: fullWidthPadding - 70, marginLeft: 18}}>
                  <Paragraph style={styles.contentText} numberOfLines={3}>{notif.event}</Paragraph>
                  <Paragraph style={styles.timeText}>{moment(notif.created_at).fromNow()}</Paragraph>
                </View>
              </Card.Content>
            </Card>
          </View>
        </TouchableHighlight>
      ))}
    </ScrollView>
  );
}


const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    paddingTop: 12
  },
  card: {
    marginHorizontal: 12,
    marginBottom: 6,
    width: fullWidthPadding,
    paddingVertical: 12
  },
  content: {
    paddingVertical: 0,
    flexDirection: 'row',
    alignItems: 'center'
  },
  contentText: {
    fontSize: 16,
    maxWidth: fullWidthPadding - 40
  },
  timeText: {
    fontSize: 12,
    alignSelf: 'flex-end',
    opacity: .4
  }
});