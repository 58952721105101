import { Colors } from "react-native-paper";

export const getTeamColor = (team) => {
  if (!team) return 'transparent';
  switch (team.toLowerCase()) {
  case 'water': return Colors.indigo700;
  case 'earth': return Colors.green800;
  case 'fire': return Colors.red800;
  case 'air': return Colors.cyan700;
  case 'bravo': return Colors.pink500;
  case 'echo': return Colors.blueGrey500;
  case 'tango': return Colors.purple500;
  case 'lion turtle': return Colors.amber600;
  default: 
    return 'transparent';
  }
};