import React from 'react';
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import { Colors } from 'react-native-paper';
import { TouchableHighlight, StyleSheet } from 'react-native';


export function LikeIconButton({liked, onPress, onLongPress}) {
  return (
    <TouchableHighlight style={styles.postAction} onPress={onPress} onLongPress={onLongPress} underlayColor={'#00000040'}>
      <MaterialCommunityIcons name={`heart${liked ? '' : '-outline'}`} size={24} color={Colors.red700} />
    </TouchableHighlight>
  );
}

export function CommentIconButton(props) {
  return (
    <TouchableHighlight style={styles.postAction} {...props} underlayColor={'#00000040'}>
      <MaterialCommunityIcons name={'comment-outline'} size={24} color={Colors.blue500} />
    </TouchableHighlight>
  );
}

export const PrevButton = (props) => (
  <TouchableHighlight style={[styles.base, {marginBottom: 24}]} underlayColor={'#00000040'} {...props}>
    <Ionicons name='ios-arrow-dropleft-circle' size={30} color={Colors.blue500} />
  </TouchableHighlight>
);

export const NextButton = (props) => (
  <TouchableHighlight style={[styles.base, {marginBottom: 24}]} underlayColor={'#00000040'} {...props}>
    <Ionicons name='ios-arrow-dropright-circle' size={30} color={Colors.blue500} />
  </TouchableHighlight>
);

export const FilterButton = props => (
  <TouchableHighlight style={styles.base} underlayColor={'#00000040'} {...props}>
    <MaterialCommunityIcons name='filter-variant' size={30} color={Colors.white} />
  </TouchableHighlight>
);

export const MenuButton = props => (
  <TouchableHighlight style={styles.menu} underlayColor={'#00000040'} {...props}>
    <MaterialCommunityIcons name='dots-horizontal' size={35} color={Colors.white} />
  </TouchableHighlight>
);

export const CloseIconButton = props => (
  <TouchableHighlight style={styles.close} underlayColor={'#00000040'} {...props}>
    <MaterialCommunityIcons name='close' size={35} color={Colors.white} />
  </TouchableHighlight>
);

export const GeneralIconButton = props => (
  <TouchableHighlight style={[styles.base, {...props.style}, {opacity: props.disabled ? .4 : 1}]} underlayColor={'#00000040'} {...props}>
    <MaterialCommunityIcons name={props.icon} size={props.size ? props.size : 35} color={props.color ? props.color : Colors.white}/>
  </TouchableHighlight>
);

const touchableStyle = {
  borderRadius: 50,
  width: 40,
  height: 40,
  justifyContent: 'center',
  alignItems: 'center',
};

const styles = StyleSheet.create({
  base: {
    ...touchableStyle
  },
  postAction: {
    ...touchableStyle,
    marginRight: 4
  },
  menu: {
    ...touchableStyle,
    margin: 10
  },
  close: {
    ...touchableStyle,
    position: 'absolute',
    top: 0,
    right: 0
  }
});