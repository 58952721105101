import React from 'react';
import { createContext, useState } from 'react';
import { saveUserDataToDevice } from '../api/storage';

const initialAuthContext = {
  authContext: {
    user: null
  },
};

export const AuthContext = createContext(initialAuthContext);

export default function AuthContextProvider({children}) {
  const [authContext, setAuthContext] = useState(initialAuthContext.authContext);

  const setUser = user => {
    saveUserDataToDevice(user)
    setAuthContext({...authContext, user});
  };

  return (
    <AuthContext.Provider value={{authContext, setUser}}>
      {children}
    </AuthContext.Provider>
  );
}