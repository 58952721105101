import React, { useState, useEffect } from 'react';
import { View, Image } from 'react-native';
import { SCREEN_WIDTH } from '../constants/Layout';
import CachedImage from '../components/CachedImage';
import { DEFAULT_IMG } from '../constants/Misc';

const imageWidth = SCREEN_WIDTH - 24 > 500 ? 500 : SCREEN_WIDTH - 24;

export default function ImageViewWeb({route}) {
  const [imageHeight, setImageHeight] = useState(imageWidth);

  const imgSource = route.params.imgSource;

  const adjustSize = (sourceWidth, sourceHeight) => {
    let ratio = 1;
    const height = undefined;

    if (imageWidth && height) {
      ratio = Math.min(imageWidth / sourceWidth, height / sourceHeight);
    }
    else if (imageWidth) {
      ratio = imageWidth / sourceWidth;
    }
    else if (height) {
      ratio = height / sourceHeight;
    }

    const computedHeight = sourceHeight * ratio;

    setImageHeight(computedHeight);
  };

  useEffect(() => {
    Image.getSize(imgSource.uri, (w, h) => adjustSize(w, h), e => console.log(e));
  }, []);

  return (
    <View style={{alignSelf: 'center'}}>
      <CachedImage
        source={imgSource}
        style={{width: imageWidth, height: imageHeight}}
        defaultSource={DEFAULT_IMG}
      /> 
    </View>
  );
}
