import React, { useState, useEffect, useContext } from 'react';
import { View, ScrollView, TextInput as RNTextInput, Platform, StyleSheet, KeyboardAvoidingView, Keyboard, LayoutAnimation, TouchableOpacity } from 'react-native';
import { TextInput, Colors, Text, Portal } from 'react-native-paper';
import { fullWidthPadding } from '../constants/Misc';
import { Ionicons } from '@expo/vector-icons';
import PlayerChip from './PlayerChip';
import {AuthContext} from '../context/AuthContext';
import { fetchAllUsers } from '../api/users';
import debounce from '../../utilities/debounce';
import ParsedText from 'react-native-parsed-text';
import { addNotification } from '../api/notifications';
import AutogrowInput from 'react-native-autogrow-input';

export default function CommentInput({submitComment, post}) {
  const [allUsers, setAllUsers] = useState([]);
  const [matchingUsers, setMatchingUsers] = useState([]);
  const [searchUserQuery, setSearchUserQuery] = useState('')
  const [commentValue, setCommentValue] = useState('');
  const [inputBottom, setInputBottom] = useState(0);

  const {user} = useContext(AuthContext).authContext

  let keyboardWillShow = null;
  let keyboardWillHide = null;

  const handleKeyboardWillShow = (event) => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setInputBottom(event.endCoordinates.height);
  };

  const handleKeyboardWillHide = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setInputBottom(0);
  };

  const postComment = () => {
    if (commentValue.length > 0) {
      const matches = commentValue.match(/@[a-zA-Z^\S]+/gim);
      if (matches?.length > 0) {
        notifyTaggedPlayers([...matches])
      }
      submitComment(commentValue);
      setCommentValue('');
      Keyboard.dismiss();
    }
  };

  const notifyTaggedPlayers = tags => {
    tags.forEach(tag => {
      const username = tag.slice(1)
      addNotification(username, post.id, `${user?.first_name} tagged you in a comment`)
    })
  }

  const filterPlayers = debounce(() => {
    let newUsers = [...allUsers];
    setMatchingUsers(
      newUsers.filter((p) => p.full_name?.toLowerCase().includes(searchUserQuery.toLowerCase()))
    );
  }, 100);

  const chooseUser = (u) => {
    const atIndex = commentValue.lastIndexOf('@')
    setCommentValue(`${commentValue.slice(0, atIndex+1)}${u.id} `)
  }

  useEffect(() => {
    fetchAllUsers().then(setAllUsers);

    if (Platform.OS === 'ios') {
      keyboardWillShow = Keyboard.addListener('keyboardWillShow', handleKeyboardWillShow);
      keyboardWillHide = Keyboard.addListener('keyboardWillHide', handleKeyboardWillHide);
    }

    return () => {
      if (Platform.OS === 'ios') {
        keyboardWillShow.remove();
        keyboardWillHide.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (commentValue.length > 0 && commentValue.includes('@')) {
      const atIndex = commentValue.lastIndexOf('@')
      const endString = commentValue.slice(atIndex)
      if (endString.includes(' ')) {
        setSearchUserQuery('')
        setMatchingUsers([])
      } else {
        const searchStr = endString.slice(1)
        setSearchUserQuery(searchStr)
      }
    } else {
      setSearchUserQuery('')
      setMatchingUsers([])
    }
  }, [commentValue]);

  useEffect(() => {
    if (searchUserQuery.length > 0) {
      filterPlayers()
    }
  }, [searchUserQuery])

  return (
      <>
        {matchingUsers.length > 0 && (
          <View style={styles.matchingPlayerList}>
            {matchingUsers.slice(0, 5).map(user => (
              <PlayerChip key={user.id} player={user} style={{marginRight: 6, marginVertical: 3}} onPress={() => chooseUser(user)} />
            ))}
          </View>
        )}
        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-end', paddingBottom: 12, marginBottom: inputBottom, backgroundColor: Colors.black}}>
          {Platform.select({
            ios: (
              <TextInput
                style={styles.commentInput}
                value={commentValue}
                onChangeText={setCommentValue}
                multiline
                placeholder='Post a comment...'
                mode='outlined'
                dense
                keyboardAppearance='dark'
              />
            ),
            android: (
              <KeyboardAvoidingView behavior='height' style={styles.commentWrapperAndroid}>
                <TextInput
                  style={styles.commentInputAndroid}
                  value={commentValue}
                  onChangeText={setCommentValue}
                  multiline
                  placeholder='Post a comment...'
                  mode='outlined'
                  dense
                  keyboardAppearance='dark'
                />
              </KeyboardAvoidingView>
            ),
            web: (
              
              <TextInput
                style={styles.commentInput}
                value={commentValue}
                onChangeText={setCommentValue}
                // multiline
                placeholder='Post a comment...'
                mode='outlined'
                dense
                onSubmitEditing={postComment}
                render={(props) => (
                  <AutogrowInput
                    {...props}
                    defaultHeight={50}
                  />
                )}
              />
            )
          })}
          <TouchableOpacity disabled={commentValue.length === 0} onPress={postComment} style={[iconStyle, {opacity: commentValue.length === 0 ? .5 : 1}]}>
            <Ionicons name='md-send' color={Colors.purple300} size={28} />
          </TouchableOpacity>
        </View>
      </>
  );
}

const iconStyle = {
  width: 48, 
  marginLeft: 12,
  marginRight: -6,
  paddingTop: 24,
  alignSelf: 'flex-end'
};

const styles = StyleSheet.create({
  contentText: {
    fontSize: 16
  },
  commentInput: {
    width: fullWidthPadding - 44,
    maxHeight: 200,
  },
  commentWrapperAndroid: {
    width: fullWidthPadding - 44,
  },
  commentInputAndroid: {
    maxHeight: 200,
  },
  matchingPlayerList: {
    width: fullWidthPadding,
    borderRadius: 8,
    marginTop: 6,
    flexDirection: 'row', 
    flexWrap: 'wrap',
    alignSelf: 'center'
  }
});