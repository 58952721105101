import React, { useState, useEffect } from 'react';
import RefreshButton from '../../components/RefreshButton';
import Feed from '../../components/feed/Feed';
import { fetchAllAnnouncements } from '../../api/announcements';

export default function AnnouncementsScreen({navigation}) {
  const [announcements, setAnnouncements] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const updateAnnouncements = () => {
    setIsLoading(true);
    fetchAllAnnouncements().then(data => {
      setIsLoading(false);
      setAnnouncements(data);
    }).catch(err => {
      console.warn('Couldnt get feed :( ', err);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    updateAnnouncements();
  }, []);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      // eslint-disable-next-line react/display-name
      headerRight: () => (
        <RefreshButton onPress={updateAnnouncements} isLoading={isLoading} />
      ),
    });
  }, [navigation, isLoading]);

  return (
    <Feed posts={announcements} updateScreen={updateAnnouncements} />
  );
}