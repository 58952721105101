import React, {useState} from 'react';
import { Platform, StatusBar, StyleSheet, View } from 'react-native';
import { SplashScreen } from 'expo';
import * as Font from 'expo-font';
import { Ionicons } from '@expo/vector-icons';
import { DarkTheme, Provider as PaperProvider } from 'react-native-paper';
import useLinking from './src/navigation/useLinking';
import AuthContextProvider from './src/context/AuthContext';
import AppNavigator from './src/navigation/AppNavigator';
import { NavigationContainer, DarkTheme as DarkNavTheme } from '@react-navigation/native';
import NetworkContextProvider from './src/context/NetworkContext';

// if (process.env.NODE_ENV === 'development') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   // @ts-ignore
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//   });
// }

console.disableYellowBox = true;

const appTheme = {
  ...DarkTheme,
  roundness: 8,
};

export default function App(props) {
  const [isLoadingComplete, setLoadingComplete] = useState(false);
  const [initialNavigationState, setInitialNavigationState] = useState();

  const containerRef = React.useRef();
  const { getInitialState } = useLinking(containerRef);

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHide();

        // Load our initial navigation state
        setInitialNavigationState(await getInitialState());

        // Load fonts
        await Font.loadAsync({
          ...Ionicons.font,
          'space-mono': require('./assets/fonts/SpaceMono-Regular.ttf'),
        });
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        SplashScreen.hide();
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  if (!isLoadingComplete && !props.skipLoadingScreen) {
    return null;
  } else {
    return (
      <AuthContextProvider>
        <NetworkContextProvider>
          <PaperProvider theme={appTheme}>
            <View style={styles.container}>
              {Platform.OS === 'ios' && <StatusBar barStyle="light-content" />}
              <NavigationContainer ref={containerRef} initialState={initialNavigationState} theme={DarkNavTheme}>
                <AppNavigator  />
              </NavigationContainer>
            </View>
          </PaperProvider>
        </NetworkContextProvider>
      </AuthContextProvider>
    
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
});
