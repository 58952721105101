import { Dimensions } from 'react-native';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

export default {
  SCREEN_WIDTH: width,
  SCREEN_HEIGHT: height,
  isSmallDevice: width < 375,
};

export const SCREEN_HEIGHT = height;
export const SCREEN_WIDTH = width;