// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { View, Modal, Image, Clipboard } from 'react-native';
import ImageZoom from 'react-native-image-pan-zoom';
import { SCREEN_WIDTH, SCREEN_HEIGHT } from '../constants/Layout';
import CachedImage from '../components/CachedImage';
import { DEFAULT_IMG } from '../constants/Misc';
import { IconButton, Colors } from 'react-native-paper';
import * as MediaLibrary from 'expo-media-library';
import * as FileSystem from 'expo-file-system';
import Swiper from 'react-native-web-swiper';
import { SafeAreaView } from 'react-native-safe-area-context';

const imageWidth = SCREEN_WIDTH - 24 > 500 ? 500 : SCREEN_WIDTH - 24;

export default function AlbumViewModal({navigation, route}) {
  const photoList = route.params.photos;
  const photoIndex = route.params.curPhotoIndex;

  const [imageHeight, setImageHeight] = useState(imageWidth);
  const [modalVisible, setModalVisible] = useState(true);
  const [curPhotoIndex, setCurPhotoIndex] = useState(photoIndex);

  const handleDownload = async () => {
    FileSystem.downloadAsync(photoList[curPhotoIndex], `${FileSystem.cacheDirectory}temp.png`).then(({uri}) => {
      MediaLibrary.saveToLibraryAsync(uri).then(() => {
        alert('Photo saved!');
      }).catch(e => {
        Clipboard.setString(e)
        alert('Photo could not be saved. Error copied to clipboard. (You can share this with your captain)');
      });
    });
  };

  const handleSwipeDown = () => {
    setModalVisible(false);
    navigation.goBack();
  };

  return (
    <Modal transparent={false} visible={modalVisible}>
      <View style={{backgroundColor: '#000', flex: 1}}>
        <SafeAreaView style={{flex: 1, position: 'relative'}}>
          <Swiper from={photoIndex} onIndexChanged={setCurPhotoIndex} controlsEnabled={true} controlsProps={{PrevComponent: PrevButton, NextComponent: NextButton}}>
            {photoList.map((photo, i) => (
              <ImageZoom
                key={i}
                cropWidth={SCREEN_WIDTH}
                cropHeight={SCREEN_HEIGHT - 80}
                imageWidth={imageWidth}
                imageHeight={imageHeight}
                enableSwipeDown
                onSwipeDown={handleSwipeDown}
              >
                <CachedImage
                  source={{uri: photo}}
                  style={{width: imageWidth, height: imageWidth}}
                  resizeMode='contain'
                  defaultSource={DEFAULT_IMG}
                /> 
              </ImageZoom>
            ))}
          </Swiper>
        
          <View style={{position: 'absolute', top: 30, left: 6}}>
            <IconButton icon='download' size={38} color={Colors.white} onPress={handleDownload} />
          </View>
          <View style={{position: 'absolute', top: 30, right: 6}}>
            <IconButton icon='close' size={38} color={Colors.white} onPress={handleSwipeDown} />
          </View>
        </SafeAreaView>
      </View>
    </Modal>
  );
}


const PrevButton = (props) => (
  <IconButton icon='chevron-left-circle' size={36} color={Colors.blue500} style={{backgroundColor: 'transparent', marginLeft: -18, marginBottom: 12}} {...props} />
);

const NextButton = (props) => (
  <IconButton icon='chevron-right-circle' size={36} color={Colors.blue500} style={{backgroundColor: 'transparent', marginRight: -18, marginBottom: 12}} {...props} />
);
