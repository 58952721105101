import { Avatar, Colors } from 'react-native-paper';

import React from 'react';
import { View, StyleSheet } from 'react-native';
import { getTeamColor } from '../../utilities/getColorForTeam';
import {Text} from 'react-native-paper'

const PlayerAvatar = (props) => {
  const imgSize = 37;
  const player = props.player
  return player?.avatar && player?.avatar.length > 0 ? (
    <View style={{flexDirection: 'row', alignItems: 'center'}}>
      <View
        style={styles(player?.team, imgSize).imageAvatar}
      >
        <Avatar.Image size={imgSize - 2} source={{ uri: player?.avatar }} />
      </View>
    </View>

  ) : (
    <Avatar.Icon
      {...props}
      size={imgSize}
      style={styles(player?.team).iconAvatar}
      icon="account"
      color={Colors.grey600}
    />
  );
};
export default PlayerAvatar;

const styles = (team = null, imgSize = 0) => StyleSheet.create({
  iconAvatar: {
    borderWidth: 2,
    borderColor: getTeamColor(team),
    backgroundColor: Colors.grey800,
  },
  imageAvatar: {
    backgroundColor: getTeamColor(team),
    borderRadius: 50,
    width: imgSize,
    height: imgSize,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: imgSize - 3,
    height: imgSize - 3
  }
});