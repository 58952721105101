import React, { useState, useEffect } from 'react';
import { getContendersForAward } from '../../../api/scoring';
import Feed from '../../../components/feed/Feed';

export default function ContenderListScreen({route}){
  const [posts, setPosts] = useState([]);

  const updatePosts = () => {
    getContendersForAward(route.params.award).then(setPosts);
  };

  useEffect(() => {
    updatePosts();
  }, []);

  return <Feed posts={posts} updateScreen={updatePosts} />;
}
