import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import FeedScreen from '../screens/feed/FeedScreen';
import PostDetailScreen from '../screens/feed/PostDetailScreen';
import ImageViewModal from '../screens/ImageViewModal';
import ImageViewWeb from '../screens/ImageViewWeb';
import AlbumViewModal from '../screens/AlbumViewModal';
import AlbumViewWeb from '../screens/AlbumViewWeb';
import ProfileScreen from '../screens/ProfileScreen';
import SearchModal from '../screens/SearchModal';
import PostsForUserScreen from '../screens/PostsForUserScreen';
import ViewChallengePostsScreen from '../screens/ViewChallengePostsScreen';

const FeedStackWithModal = createStackNavigator();
const FeedStackWithoutModal = createStackNavigator();

export default function FeedStack() {
  return (
    <FeedStackWithModal.Navigator mode="modal">
      <FeedStackWithModal.Screen
        name="Feed"
        component={PlainFeedStack}
        options={{ headerShown: false }}
      />
      <FeedStackWithModal.Screen name="ImageViewer" component={ImageViewModal} />
      <FeedStackWithModal.Screen name="AlbumViewer" component={AlbumViewModal} />
      <FeedStackWithModal.Screen name="ImageViewWeb" component={ImageViewWeb} />
      <FeedStackWithModal.Screen name="AlbumViewerWeb" component={AlbumViewWeb} />
      <FeedStackWithModal.Screen name="Search" component={SearchModal} />
    </FeedStackWithModal.Navigator>
  );
}

function PlainFeedStack() {
  return (
  <FeedStackWithoutModal.Navigator>
      <FeedStackWithoutModal.Screen
        name="Feed"
        component={FeedScreen}
        />
      <FeedStackWithoutModal.Screen
        name="PostDetail"
        component={PostDetailScreen}
        options={{
          title: 'Post Detail'
        }} />
        <FeedStackWithoutModal.Screen
          name="Profile"
          component={ProfileScreen}
          options={({route}) => ({
            title: route?.params?.user?.full_name || 'Profile'
          })} />
        <FeedStackWithoutModal.Screen
          name="PostsForUser"
          component={PostsForUserScreen}
          options={({route}) => ({
            title: `Posts by ${route?.params?.user?.full_name || 'User'}`
          })}
        />
        <FeedStackWithoutModal.Screen
          name="ViewChallengePostsScreen"
          component={ViewChallengePostsScreen}
          options={({route}) => ({title: `#${route.params.challenge}`})}
        />
    </FeedStackWithoutModal.Navigator>
  );
}