import React, { useContext, useState } from 'react';
import { Menu, Divider } from 'react-native-paper';
import { MenuButton } from '../IconButtons';
import { AuthContext } from '../../context/AuthContext';

export default function PostMenu({author, visible, setVisible, showAwardDialog, showContenderDialog, copyText, deletePost}) {
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false)
  const {authContext} = useContext(AuthContext)
  const {user} = authContext

  const close = () => setVisible(false)
  const open = () => setVisible(true)

  const removePost = () => {
    setDeleteButtonDisabled(true)
    deletePost()
  }

  return (
    <Menu
    visible={visible}
    onDismiss={close}
    anchor={<MenuButton onPress={open} />}
  >
    {user.admin && (
      <>
        <Menu.Item
          onPress={showAwardDialog}
          icon='plus'
          title="Toggle Award"
        />
        <Menu.Item
          onPress={showContenderDialog}
          icon='plus'
          title="Toggle Contender"
        />
      </>
    )}
    <Menu.Item
      onPress={copyText}
      title="Copy Text"
    />
    {(author?.id === user?.id || user?.admin) && (
        <Menu.Item
          disabled={deleteButtonDisabled}
          onPress={removePost}
          title="Delete Post"
        />
    )}
    <Divider />
    <Menu.Item
      onPress={close}
      title="Close Menu"
    />
  </Menu>
  )
}