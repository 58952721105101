import * as WebBrowser from 'expo-web-browser';

import { Card, Colors, Paragraph } from 'react-native-paper';
import React, { useEffect, useState } from 'react';

import { StyleSheet, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import ButtonWrapper from '../ButtonWrapper';
import ParsedText from 'react-native-parsed-text';
import { getChallengeColor } from '../../../utilities/getColorForChallenge';
import { fullWidthPadding } from '../../constants/Misc';

export default function ChallengeCard({
  challenge,
  postsByUser,
  onButtonPress,
  currentDay,
  selectedDay,
}) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { title, description, category } = challenge;
  const scoreRange = challenge.score_range;

  const navigation = useNavigation();

  const onPress = () => {
    onButtonPress(challenge);
  };

  const getSubmitButtonText = () => {
    let text = 'Submit';
    if (isSubmitted) text = 'Submitted';
    return text;
  };

  const handleHyperlink = (url) => {
    let link = url;
    if (!url.includes('http')) link = `http://${url.trim().toLowerCase()}`;
    WebBrowser.openBrowserAsync(link).catch(e => alert(`Couldnt open link: ${link} \n\nError: ${e}`));
  };

  const viewPosts = () => {
    navigation.navigate('ViewChallengePostsScreen', { challenge: title });
  };

  const checkIfSubmitted = () => {
    if (category === 'war') {
      setIsSubmitted(false);
      return;
    }
    if (title === 'DankMemeMachine') {
      setIsSubmitted(false);
      return;
    }
    const index = postsByUser.findIndex((p) => p.challenge === title);
    setIsSubmitted(index >= 0);
  };

  useEffect(() => {
    checkIfSubmitted();
  }, [postsByUser]);

  return (
    <Card style={styles.container}>
      <Card.Title title={`#${title}`} />
      <Card.Content style={styles.content}>
        <Paragraph style={styles.contentText}>
          {scoreRange ? (
            <Text style={{fontSize: 13, color: getChallengeColor(category), fontWeight: '600', marginRight: 12}}>
              {scoreRange}{'\n\n'}
            </Text>
          ) : (
            <Text>{'\n'}</Text>
          )}
          <ParsedText
            parse={
              [
                {type: 'url', style: styles.linkStyle, onPress: handleHyperlink},
              ]
            }
          >
            {description}
          </ParsedText>
        </Paragraph>
      </Card.Content>
      <Card.Actions style={styles.cardActions}>
        <ButtonWrapper mode="outlined" onPress={viewPosts} style={{ marginRight: 12 }} disabled={challenge.category === 'war' && !challenge.enabled}>
          {challenge.category === 'war' ? 'Enter Challenge' : 'View Posts'}
        </ButtonWrapper>
        <ButtonWrapper
          mode="contained"
          onPress={onPress}
          disabled={isSubmitted || (challenge.category === 'war' && !challenge.enabled)}
        >
          {getSubmitButtonText()}
        </ButtonWrapper>
      </Card.Actions>
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 12,
    width: fullWidthPadding,
    alignSelf: 'center'
  },
  content: {
    paddingBottom: 8,
    paddingTop: 0,
    marginTop: -16
  },
  contentText: {
    fontSize: 16,
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  linkStyle: {
    color: Colors.purple200,
    textDecorationLine: 'underline',
  },
});
