import {View} from 'react-native';
import { Video } from 'expo-av';

import React, { useRef } from 'react';
import { fullWidthPadding } from '../constants/Misc';
import { useFocusEffect } from '@react-navigation/native';

export default function HuntVideo({videoUrl}) {

  const ref = useRef(null)

  useFocusEffect(
    React.useCallback(() => {
      return () => {
        if (ref && ref.current) {
          ref?.current?.pauseAsync()
        }
      };
    }, [])
  );

  return !videoUrl ? null : (
    <View style={styles.wrapper}>
      <Video
        ref={ref}
        source={{ uri: videoUrl }}
        rate={1.0}
        volume={1.0}
        isMuted={false}
        resizeMode="contain"
        shouldPlay={false}
        isLooping
        style={styles.vid}
        useNativeControls={true}
      />
    </View>
  )
}

const styles = {
  vid: {
    width: fullWidthPadding - 2,
    height: (fullWidthPadding - 2),
  },
  wrapper: {
    width: fullWidthPadding - 2,
    height: (fullWidthPadding - 2),
    backgroundColor: '#121212',
    justifyContent: 'center',
    alignItems: 'center',
  }
};
