import * as firebase from 'firebase';

import TheHunt from '../constants/Hunt';
import { firestore } from './firebase';
import { getUserRefById } from './users';
import moment from 'moment';

const fsIncrement = firebase.firestore.FieldValue.increment(1);
const fsDecrement = firebase.firestore.FieldValue.increment(-1);

const sortPostsBy = (a, b) => b.created_at - a.created_at;

let gamePosts = null;

export const fetchAllPosts = async (start) => {
  return new Promise((resolve, reject) => {
    let posts = [];
    firestore
      .collection('posts')
      .orderBy('created_at', 'desc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          posts.push({ ...doc.data(), id: doc.id });
        });
        convertAuthorReference(posts).then((authors) => {
          posts.forEach((post, i) => {
            post.author = authors[i].data();
            // post.engagementScore = getEngagementScore(post);
          });
          // const result = posts.sort(sortPostsBy);
          gamePosts = posts;
          resolve(posts);
        });
      })
      .catch((e) => reject(e));
  });
};

const getEngagementScore = (post) => {
  let score = 0;

  // Based on length of time since posting
  const now = new Date().getTime();
  const elapsedTime = now - post.created_at;
  const input = (elapsedTime * 800) / 28800000;
  const timeScore = Math.cos(input / 500) * 100;
  score += timeScore < 20 ? 20 : timeScore;

  // comments
  score += post.comment_count * 2;

  // likes
  score += post.liked_by.length * 1;

  return score;
};

export const convertAuthorReference = (posts) => {
  const promises = [];
  posts.forEach((p) => promises.push(p.author.get()));
  return Promise.all(promises);
};

export const uploadPost = async (
  author,
  attachments,
  postCaption,
  challenge
) => {
  const postAuthor = await getUserRefById(author.id);
  const data = {
    author: postAuthor,
    team: author.team,
    attachments,
    content: postCaption,
    challenge,
    liked_by: [],
    score: 0,
    scored: false,
    awards: [],
    created_at: new Date().getTime(),
  };
  firestore
    .collection('teams')
    .doc(author.team)
    .update({ num_posts_submitted: fsIncrement });
  const response = await firestore
    .collection('posts')
    .doc(`${author.id}-${challenge}-${data.created_at}`)
    .set(data);
  return response;
};

export const getPostsForUserId = (userId) => {
  const userRef = getUserRefById(userId);
  let posts = [];
  return new Promise((resolve, reject) => {
    firestore
      .collection('posts')
      .where('author', '==', userRef)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          posts.push({ ...doc.data(), id: doc.id });
        });
        convertAuthorReference(posts).then((authors) => {
          posts.forEach((post, i) => {
            post.author = authors[i].data();
          });
          resolve(posts.sort(sortPostsBy));
        });
      })
      .catch((e) => reject(e));
  });
};

export const getPostsForTeam = (team) => {
  let posts = [];
  return new Promise((resolve, reject) => {
    firestore
      .collection('posts')
      .where('team', '==', team)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          posts.push({ ...doc.data(), id: doc.id });
        });
        convertAuthorReference(posts).then((authors) => {
          posts.forEach((post, i) => {
            post.author = authors[i].data();
          });
          resolve(posts.sort(sortPostsBy));
        });
      })
      .catch((e) => reject(e));
  });
};

export const getPostsForChallenge = (challenge) => {
  let posts = [];
  return new Promise((resolve, reject) => {
    firestore
      .collection('posts')
      .where('challenge', '==', challenge)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          posts.push({ ...doc.data(), id: doc.id });
        });
        convertAuthorReference(posts).then((authors) => {
          posts.forEach((post, i) => {
            post.author = authors[i].data();
          });
          resolve(posts.sort(sortPostsBy));
        });
      })
      .catch((e) => reject(e));
  });
};

export const updateLikesForPost = (postId, likes) => {
  firestore.collection('posts').doc(postId).update(likes);
};

export const deletePost = async (post) => {
  firestore.collection('posts').doc(post.id).delete();

  firestore
    .collection('teams')
    .doc(post.author.team)
    .update({ num_posts_submitted: fsDecrement });

  const commentsRef = firestore.collection('comments');
  commentsRef
    .where('on_post', '==', post.id)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        commentsRef.doc(doc.id).delete();
      });
    });

  const notifRef = firestore.collection('notifications');
  notifRef
    .where('for_post', '==', post.id)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        notifRef.doc(doc.id).delete();
      });
    });
};

export const getPostById = (postId) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('posts')
      .doc(postId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const post = { ...doc.data(), id: postId };
          convertAuthorReference([post]).then((authors) => {
            post.author = authors[0].data();
            resolve(post);
          });
        } else {
          reject(`doc doesnt exist for id ${postId}`);
        }
      })
      .catch(reject);
  });
};

export const getPostsForDay = (day = 1) => {
  return new Promise((resolve, reject) => {
    const selectedDay = moment(TheHunt.startDate).utc();
    selectedDay.add(day - 1, 'days');

    const isSameDay = (created) => {
      const mo = moment(created);
      return (
        mo.date() === selectedDay.date() &&
        mo.month() === selectedDay.month() &&
        mo.year() === selectedDay.year()
      );
    };

    let posts = [];
    const postsRef = firestore.collection('posts');
    postsRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (isSameDay(doc.data().created_at)) {
            posts.push({ ...doc.data(), id: doc.id });
          }
        });
        convertAuthorReference(posts).then((authors) => {
          posts.forEach((post, i) => {
            post.author = authors[i].data();
          });
          resolve(posts.sort(sortPostsBy));
        });
      })
      .catch((e) => reject(e));
  });
};

export const getUnscoredPosts = () => {
  return new Promise((resolve, reject) => {
    let posts = [];
    const postsRef = firestore.collection('posts');
    const query = postsRef.where('scored', '==', false);
    query
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          posts.push({ ...doc.data(), id: doc.id });
        });
        convertAuthorReference(posts).then((authors) => {
          posts.forEach((post, i) => {
            post.author = authors[i].data();
          });
          resolve(posts.sort(sortPostsBy));
        });
      })
      .catch((e) => reject(e));
  });
};
