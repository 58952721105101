import React, { useEffect, useState } from 'react';
import { Portal, Dialog, Text, Button } from 'react-native-paper';
import { fullWidthPadding } from '../../constants/Misc';
import { getUserById } from '../../api/users';

export default function LikesDialog({liked_by, visible, setVisible}) {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    let userPromises = [];
    liked_by.forEach(l => userPromises.push(getUserById(l)));
    Promise.all(userPromises).then(setUsers);
  }, [liked_by]);

  return (
    <Portal>
      <Dialog
        style={{
          width: fullWidthPadding - 48,
          marginBottom: 200,
          alignSelf: 'center',
        }}
        visible={visible}
        onDismiss={() => setVisible(false)}
      >
        <Dialog.Title>
        Liked By
        </Dialog.Title>
        <Dialog.Content>
          {users.map(u => (
            <Text key={u.id}>{u.full_name}</Text>
          ))}
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={() => setVisible(false)}>Close</Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
}