import React from 'react';
import { Colors, Chip } from 'react-native-paper';
import { View, StyleSheet } from 'react-native';
import { getTeamColor } from '../../../utilities/getColorForTeam';
import { getChallengeColor } from '../../../utilities/getColorForChallenge';
import { useNavigation } from '@react-navigation/native';
import PlayerChip from '../PlayerChip';
import { fullWidthPadding } from '../../constants/Misc';

export default function FeedCardTitle({player, challenge = null, outline = false, ...props}) {

  const navigation = useNavigation()

  const onChallengePress = () => {
    navigation.navigate("ViewChallengePostsScreen", { challenge: challenge?.title });
  }
  return (
    <View style={{flexDirection: 'row', alignItems: 'center', marginLeft: 12, maxWidth: fullWidthPadding / 2 - 30}}>
      {challenge && (
        <Chip style={{borderColor: getChallengeColor(challenge.category), borderWidth: 1,marginRight: 8}} onPress={onChallengePress}>#{challenge.title}</Chip>
      )}
      <PlayerChip style={{maxWidth: fullWidthPadding / 2 - 30}} player={player} />
    </View>
  )
}