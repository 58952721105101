// This file contains values that are unique to each GAME of #TheHunt

const getStartDate = () => {
  const date = new Date();
  date.setDate(27);
  date.setMonth(3);
  date.setFullYear(2020);
  date.setHours(0);
  date.setMinutes(0);
  return date;
};

const TheHunt = {
  startDate: getStartDate(),
  length: 6, 
  version: '3.4'
};

export default TheHunt;