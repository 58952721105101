import {firestore} from './firebase';
import * as firebase from 'firebase';
import { getUserRefById } from './users';
import { addNotification } from './notifications';

export const fsDecrement = firebase.firestore.FieldValue.increment(-1);

const sortCommentsBy = (a, b) => a.created_at - b.created_at;

export const fetchCommentsForPostId = async (postId) => {
  return new Promise((resolve, reject) => {
    let comments = [];
    firestore.collection('comments').where('on_post', '==', postId).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        comments.push({...doc.data(), id: doc.id});
      });
      convertAuthorReference(comments).then(authors => {
        comments.forEach((post, i) => {
          post.author = authors[i].data();
        });
        resolve(comments.sort(sortCommentsBy));
      });
    }).catch(e => reject(e));
  });
};

export const convertAuthorReference = comments => {
  const promises = [];
  comments.forEach(p => promises.push(p.author.get()));
  return Promise.all(promises);
};

export const submitCommentForPost = (authorId, commentValue, postId, commentNumber) => {
  const commentId = `${postId}-${commentNumber}`;
  const authorRef = getUserRefById(authorId);
  const data = {
    author: authorRef,
    content: commentValue,
    on_post: postId,
    created_at: (new Date).getTime(),
    liked_by: []
  };
  firestore.collection('comments').doc(commentId).set(data);

  // increase comment count 
  firestore.collection('posts').doc(postId).update({comment_count: commentNumber}).catch(() => {});
  firestore.collection('announcements').doc(postId).update({comment_count: commentNumber}).catch(() => {});

  // add notification
  authorRef.get().then(doc => {
    if (doc.exists) {
      const postAuthorId = postId.slice(0, postId.indexOf('-'));
      const challenge = postId.slice(postId.indexOf('-') + 1, postId.lastIndexOf('-'));
      if (postAuthorId !== doc.data().id) {
        addNotification(postAuthorId, postId, `${doc.data().first_name} commented on your #${challenge} post: "${commentValue}"`, commentId);
      }
    }
  });
};

export const deleteComment = async (comment) => {
  const col = comment.on_post.includes('announcement') ? 'announcements' : 'posts';
  const ret = [];
  ret.push(firestore.collection(col).doc(comment.on_post).update({comment_count: fsDecrement}));
  ret.push(firestore.collection('comments').doc(comment.id).delete());
  firestore.collection('notifications').where('event_id', '==', comment.id).get()
    .then(query => {
      query.forEach(doc => firestore.collection('notifications').doc(doc.id).delete())
    });
  return Promise.all(ret);
};

export const updateLikesForComment = (commentId, likes) => {
  firestore.collection('comments').doc(commentId).update(likes);
};