// @ts-nocheck
import React, { useState } from 'react';
import {View, FlatList, StyleSheet} from 'react-native';
import {Text} from 'react-native-paper';
import FeedCard from './FeedCard';
import { useNavigation } from '@react-navigation/native';
import { UpdateContext } from '../../context/UpdateContext';

export default function Feed({posts = [], updateScreen}) {
  const navigation = useNavigation();

  const navigateToPostDetail = post => {
    navigation.navigate('PostDetail', {post});
  };

  const renderItem = ({item, index}) => {
    const nav = () => navigateToPostDetail(item);
    return <FeedCard key={index} item={item} navToDetail={nav} />;
  };

  return (
    <UpdateContext.Provider value={{updateScreen}}>
      <FlatList
        style={styles.list}
        contentContainerStyle={styles.container}
        data={posts}
        renderItem={renderItem}
        initialNumToRender={3}
        windowSize={5}
        showsVerticalScrollIndicator={false}
        ListFooterComponent={Footer}
        ListEmptyComponent={Empty}
      />
    </UpdateContext.Provider>
  );
}

const Footer = () => (
  <View style={{height: 150}} />
);

const Empty = () => (
  <View style={{height: 200, justifyContent: 'center', alignItems: 'center'}}>
    <Text style={{fontSize: 24, opacity: .4}}>No posts here!</Text>
  </View>
);

const styles = StyleSheet.create({
  list: {
    padding: 12
  },
  container: {
    alignItems: 'center'
  }
});