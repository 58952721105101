import { firestore } from './firebase';

let gameUsers = null;

export const fetchAllUsers = () => {
  return new Promise((resolve, reject) => {
    let users = [];
    firestore.collection('users').get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        users.push({...doc.data(), id: doc.id});
      });
      const result = users.sort((a, b) => a.first_name - b.first_name).filter(u => u.id !== 'topten');
      gameUsers = result;
      resolve(result);
    }).catch(e => reject(e));
  });
};

export const getUserById = (userId) => {
  return new Promise((resolve, reject) => {
    if (gameUsers) {
      const u = gameUsers.find(gameUser => gameUser.id === userId)
      if (u) resolve(u);
    }
    firestore.collection('users').doc(userId).get()
      .then(doc => {
        if (doc.exists) {
          const user = {...doc.data(), id: userId};
          resolve(user);
        } else {
          reject(null);
        }
      })
      .catch(reject);
  });
};

export const getUserRefById = (id) => {
  return firestore.collection('users').doc(id);
};

export const getUserForEntryCode = (code) => {
  const formattedCode = code.toLowerCase();
  if (gameUsers) {
    const userIndex = gameUsers.findIndex(g => g.entry_code === formattedCode);
    if (userIndex >= 0) return gameUsers[userIndex];
  } else {
    return new Promise((resolve, reject) => {
      firestore.collection('users').where('entry_code', '==', formattedCode).get()
        .then(querySnapshot => {
          if (!querySnapshot.empty) {
            querySnapshot.docs.forEach(doc => {
              resolve(doc.data());
            });
          } else {
            reject({});
          }
        })
        .catch(err => {
          console.warn('Error getting user for entry code ', code, err);
          reject(err);
        });
    });
  }
};

export const addAvatarToUser = (userId, avatar) => {
  return firestore.collection('users').doc(userId).update({avatar});
};

export const addPushTokenToUser = (userId, token) => {
  return firestore.collection('users').doc(userId).update({push_token: token});
};