import { FlatList, View, Platform, ScrollView } from "react-native";
import { List, Searchbar } from "react-native-paper";
import React, { useEffect, useState } from "react";

import PlayerAvatar from "../../components/PlayerAvatar";
import debounce from "../../../utilities/debounce";
import { fetchAllUsers } from "../../api/users";
import { fullWidthPadding } from "../../constants/Misc";

export default function PlayerListScreen({ navigation }) {
  const [players, setPlayers] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [listHeight, setListHeight] = useState(0)

  const updatePlayers = () => {
    fetchAllUsers().then((p) => {
      setPlayers(p);
      setFilteredPlayers(p);
    });
  };

  const navToPlayer = (player) => {
    navigation.navigate("Profile", { user: player });
  };

  const onChangeSearch = (value) => {
    setSearchQuery(value);
  };

  const filterPlayers = debounce(() => {
    let newPlayers = [...players];
    setFilteredPlayers(
      newPlayers.filter((p) => p.full_name?.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  }, 500);

  const renderListItem = ({ item: player }) => {
    return (
      <List.Item
        title={player.full_name}
        titleStyle={{ marginLeft: 6 }}
        style={{ marginLeft: -6 }}
        left={(props) => <PlayerAvatar {...props} player={player} />}
        onPress={() => navToPlayer(player)}
      />
    );
  };

  useEffect(() => {
    updatePlayers();
  }, []);

  useEffect(() => {
    if (searchQuery.length > 0) {
      filterPlayers();
    } else {
      setFilteredPlayers(players);
    }
  }, [searchQuery]);

  return (
    <View style={{ flex: 1, alignItems: 'center'}}>
        <Searchbar
          style={{ marginVertical: 12, width: fullWidthPadding }}
          placeholder="Search"
          onChangeText={onChangeSearch}
          value={searchQuery}
        />
          <FlatList
            style={{ height: listHeight, width: fullWidthPadding }}
            onLayout={({nativeEvent}) => setListHeight(nativeEvent.layout.height)}
            data={filteredPlayers}
            renderItem={renderListItem}
            keyExtractor={(item) => item.id}
            showsVerticalScrollIndicator={false}
            ListFooterComponent={() => <View style={{ height: 250 }} />}
          />
    </View>
  );
}
