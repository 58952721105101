import React, { useContext } from 'react';
import { Menu, Divider } from 'react-native-paper';
import { MenuButton } from './IconButtons';
import { AuthContext } from '../context/AuthContext';

export default function CommentMenu({visible, setVisible, comment, handleCopy, removeComment}) {

  const {authContext} = useContext(AuthContext)
  const {user} = authContext

  const close = () => setVisible(false)
  const open = () => setVisible(true)

  const copy = () => {
    handleCopy(comment.content)
  }

  const deleteComment = () => {
    close()
    removeComment(comment)
  }

  return (
    <Menu
      visible={visible}
      onDismiss={close}
      anchor={<MenuButton onPress={open} />}
    >
        <Menu.Item
          onPress={copy}
          title="Copy Text"
        />
      {(comment.author?.id === user?.id || user?.admin) && (
        <Menu.Item
          onPress={deleteComment}
          title="Delete Comment"
        />
      )}
      <Divider />
      <Menu.Item
        onPress={close}
        title="Close Menu"
      />
    </Menu>
  )
}