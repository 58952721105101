import React, { useState, useContext } from 'react';
import { Card, Button, TextInput, Text, Colors } from 'react-native-paper';
import { View, ScrollView, StyleSheet, Platform } from 'react-native';
import HuntAlbum from './HuntAlbum';
import HuntImage from './HuntImage';
import * as ImagePicker from 'expo-image-picker';
import { AuthContext } from '../context/AuthContext';
import { fullWidthPadding, maxColumnWidth } from '../constants/Misc';
import HuntVideo from './HuntVideo';
import { CloseIconButton } from './IconButtons';
import ButtonWrapper from './ButtonWrapper';
import FeedCardTitle from './postCard/FeedCardTitle';

const mediaButtons = [{type: 'photo'}, {type: 'album'}, {type: 'video'}];

export default function PostCreator({error = null, challenge, submitPost, isSubmitting}) {
  const [postCaption, setPostCaption] = useState('');
  const [activeTab, setActiveTab] = useState('photo');
  const [curAlbumIndex, setCurAlbumIndex] = useState(0);

  const [singlePhoto, setSinglePhoto] = useState(null);
  const [album, setAlbum] = useState([]);
  const [videoUrl, setVideoUrl] = useState(null);

  const {authContext} = useContext(AuthContext);
  const {user} = authContext;

  const selectPhoto = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      quality: Platform.OS === 'android' ? .2 : 0,
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
    });
    if (!result.cancelled) {
      setSinglePhoto(result);
    }
  };

  const addPhotoToAlbum = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      quality: Platform.OS === 'android' ? .2 : 0,
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsMultipleSelection: true
    });
    if (!result.cancelled) {
      const newAlbum = [...album];
      setAlbum([]);
      newAlbum.push(result.uri);
      setAlbum(newAlbum);
    }
  };

  const removeCurrentAlbumPhoto = () => {
    const newAlbum = [...album];
    newAlbum.splice(curAlbumIndex, 1);
    setAlbum([]);
    setTimeout(() => setAlbum(newAlbum), 50);
  };

  const chooseVideo = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      quality: 0,
      mediaTypes: ImagePicker.MediaTypeOptions.Videos,
    });
    if (!result.cancelled) {
      setVideoUrl(result.uri)
    }
  };

  const renderActiveMediaTab = () => {
    switch(activeTab) {
    case 'photo': {
      if (singlePhoto) {
        return (
          <View>
            <HuntImage source={{uri: singlePhoto.uri}} disableLightbox />
            <Button icon='trash-can-outline' mode='outlined' style={{marginTop: 18, alignSelf: 'center'}} onPress={() => setSinglePhoto(null)}>Remove</Button>
          </View>
        );
      } else {
        return <EmptyState type='photo' onPress={selectPhoto} />;
      }
    }
    case 'album':
      if (album.length > 0) {
        return (
          <View style={{position: 'relative'}}>
            <HuntAlbum photos={album} disableLightbox onIndexChanged={setCurAlbumIndex} />
            <CloseIconButton onPress={removeCurrentAlbumPhoto} />
            <View style={{flexDirection: 'row', justifyContent: 'center'}}>
              <Button icon='image-multiple' style={{alignSelf: 'center', marginTop: 18, marginRight: 12}} mode='contained' onPress={addPhotoToAlbum}>Add to Album</Button>
              <Button icon='trash-can-outline' style={{alignSelf: 'center', marginTop: 18}} mode='outlined' onPress={() => setAlbum([])}>Remove</Button>
            </View>
          </View>
        );
      } else {
        return <EmptyState type='album' onPress={addPhotoToAlbum} />;
      }
    case 'video':
      if (videoUrl) {
        if (Platform.OS === 'web') {
          return (
            <View style={styles.default}>
              <Text style={{width: fullWidthPadding / 2, opacity: .4, textAlign: 'center', fontSize: 18, marginBottom: 12}}>Video previews don&apos;t work on the web, but don&apos;t worry - your video is safe and sound!</Text>
              <ButtonWrapper icon='trash-can-outline' onPress={() => setVideoUrl(null)} mode='outlined'>Remove Video</ButtonWrapper>
            </View>
          )
        }
      return (
          <View>
            <HuntVideo videoUrl={videoUrl} />
            <Button icon='trash-can-outline' mode='outlined' style={{alignSelf: 'center', marginTop: 18}} onPress={() => setVideoUrl(null)}>Remove</Button>
            <Text style={{width: fullWidthPadding / 2, opacity: .4, textAlign: 'center', alignSelf: 'center', marginTop: 24}}>Videos may take a hot minute to upload. Be patient!{'\n\n'}If it's taking too long, consider uploading to YouTube and dropping a link instead.</Text>
          </View>
        );
      } else {
        return <EmptyState type='video' onPress={chooseVideo} />;
      }
    default: 
      return null;
    }
  };

  const submit = () => {
    submitPost(singlePhoto, album, videoUrl, postCaption);
  };

  const hasContent = singlePhoto || videoUrl || album.length > 0 || postCaption.length > 0;
  return (
    <ScrollView contentContainerStyle={{alignItems: 'center'}}>
      <Card style={styles.container}>
        <FeedCardTitle player={user} challenge={challenge} />
        <Card.Content style={styles.content}>
          <TextInput
            label='Caption'
            value={postCaption}
            onChangeText={setPostCaption}
            mode='outlined'
            multiline
          />
        </Card.Content>
        <View style={{flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', paddingVertical: 8}}>
          {mediaButtons.map((tab, i) => <Button key={i} mode={activeTab === tab.type ? 'contained' : 'text'} onPress={() => setActiveTab(tab.type)}>{tab.type}</Button>)}
        </View>
        {renderActiveMediaTab()}
        <Card.Actions />
      </Card>
      <Button mode='contained' disabled={!hasContent || isSubmitting} loading={isSubmitting} onPress={submit}>{isSubmitting ? 'Submitting...' : 'Submit Post'}</Button>
      <Text style={styles.errorText}>{error}</Text>
      <View style={{height: 250}} />
    </ScrollView>
  );
}

function EmptyState({type, onPress}) {
  const getIcon = () => {
    switch(type) {
    case 'photo': return 'image';
    case 'album': return 'image-multiple';
    case 'video': return 'video';
    default: return 'image';
    }
  };
  
  const handleOnPress = () => {
    onPress();
  };

  return (
    <View style={styles.default}>
      <Button disabled={false} icon={getIcon()} mode='contained' onPress={handleOnPress}>Add {type === 'album' ? 'to ' : ''}{type}</Button>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 12,
    paddingTop: 12,
    width: fullWidthPadding,
    maxWidth: maxColumnWidth,
  },
  content: {
    paddingVertical: 14,
    marginTop: 0,
  },
  contentText: {
    fontSize: 16
  },
  errorText: {
    color: Colors.red500,
    marginTop: 12,
    maxWidth: fullWidthPadding / 2,
    textAlign: 'center'
  },
  default: {
    width: fullWidthPadding,
    height: fullWidthPadding,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#121212'
  }
});