import React, { Component, useState, useEffect } from 'react';
import { View, ScrollView, TouchableHighlight, StyleSheet, FlatList, Linking } from 'react-native';
import { Avatar, Text, List, Colors } from 'react-native-paper';
import * as WebBrowser from 'expo-web-browser'
import { fullWidthPadding } from '../../constants/Misc';
import { getTeamColor } from '../../../utilities/getColorForTeam';
import { getPlayersOnTeam } from '../../api/teams';
import PlayerAvatar from '../../components/PlayerAvatar';

export default function TeamProfileScreen({navigation, route}) {
  const [teamPlayers, setTeamPlayers] = useState([])
  const {team} = route.params

  const navToPostsForTeam = () => {
    navigation.navigate('TeamFeed', {team})
  };

  const handleContact = (url) => {
    WebBrowser.openBrowserAsync(url).catch(console.warn)
  }

  const navToPlayer = (player) => {
    navigation.navigate("Profile", { user: player });
  };

  const renderListItem = ({ item: player }) => {
    return (
      <List.Item
        title={player.full_name}
        titleStyle={{ marginLeft: 6 }}
        style={{ marginLeft: -6 }}
        left={(props) => <PlayerAvatar {...props} player={player} />}
        onPress={() => navToPlayer(player)}
      />
    );
  };

  useEffect(() => {
    getPlayersOnTeam(team.name).then(setTeamPlayers)
  }, [])

  return (
    <ScrollView contentContainerStyle={{alignItems: 'center'}}>
      <View style={[styles.gladiatorWrapper, {backgroundColor: getTeamColor(team.name)}]}>
        <Text style={styles.gladiatorScore}>Team {team.name.charAt(0).toUpperCase()}{team.name.slice(1)}</Text>
      </View>

      <FlatList
        scrollEnabled={false}
        style={{width: fullWidthPadding}}
        data={[
          {title: `View Posts by Team ${team?.name.charAt(0).toUpperCase()}${team?.name.slice(1)}`, icon: 'image-multiple', action: navToPostsForTeam}
        ]}
        renderItem={({item, index}) => (
          <List.Item
            key={index}
            title={item.title}
            left={props => <List.Icon {...props} icon={item.icon} />}
            onPress={item.action}
          />
        )} />

        <FlatList
          scrollEnabled={false}
          style={{  width: fullWidthPadding, marginTop: 48 }}
          // onLayout={({nativeEvent}) => setListHeight(nativeEvent.layout.height)}
          data={teamPlayers}
          renderItem={renderListItem}
          keyExtractor={(item) => item.id}
          showsVerticalScrollIndicator={false}
          ListFooterComponent={() => <View style={{ height: 250 }} />}
        />

    </ScrollView>
  )
}

const styles = StyleSheet.create({
  gladiatorWrapper: {
    margin: 12,
    backgroundColor: Colors.grey900,
    borderRadius: 8,
    width: fullWidthPadding,
    height: 80,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  gladiatorScore: {
    fontSize: 24,
    fontWeight: '700',
    textAlign: 'center'
  }
})