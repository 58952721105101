import React, { useState } from 'react';
import {View} from 'react-native'
import { Dialog, Portal, Paragraph, Text, TextInput, Button } from 'react-native-paper';
import { fullWidthPadding } from '../../constants/Misc';
import { Slider } from 'react-native';

export default function ScoreDialog({visible, setVisible, author, currentScore, challenge, challengeObject, handleScorePost}) {

  const base = challengeObject?.base ?? 0
  const bonusMin = challengeObject?.bonus_min ?? 0
  const bonusMax = challengeObject?.bonus_max ?? 0

  const [scoreValue, setScoreValue] = useState(currentScore > 0 ? currentScore : (base + bonusMax / 2) ?? 0)

  const close = () => setVisible(false)
  const open = () => setVisible(true)

  const handleScore = () => {
    handleScorePost(scoreValue)
  }

  return (
    <Portal>
    <Dialog
      style={{
        width: fullWidthPadding - 48,
        marginBottom: 200,
        alignSelf: "center",
      }}
      visible={visible}
      onDismiss={close}
    >
      <Dialog.Title>
        Score {author?.first_name}'s post for #{challenge}
      </Dialog.Title>
      <Dialog.Content>
        <Paragraph>Score range: {challengeObject?.score_range}</Paragraph>
        <TextInput
          mode="outlined"
          placeholder="Score (optional)"
          dense
          value={`${scoreValue > 0 ? scoreValue : ''}`}
          onChangeText={value => setScoreValue(parseInt(value))}
          autoFocus
          onSubmitEditing={handleScore}
          keyboardType="number-pad"
        />
        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 24}}>
          <Text>{base + bonusMin}</Text>
          <Text style={{fontSize: 22, fontWeight: '700'}}>{scoreValue}</Text>
          <Text>{base + bonusMax}</Text>
        </View>
        <Slider
          minimumValue={base + bonusMin}
          maximumValue={base + bonusMax}
          value={scoreValue}
          onValueChange={value => setScoreValue(Math.round(value))}
        />
      </Dialog.Content>
      <Dialog.Actions>
        <Button onPress={close}>Cancel</Button>
        <Button onPress={handleScore} mode="contained">
          Submit
        </Button>
      </Dialog.Actions>
    </Dialog>
  </Portal>
  )
}